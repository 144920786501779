import { LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import { GREY_25 } from "../comparisonSpace/styles";
import { Button } from "../../common/button/Button";
import { useNavigateScenarios } from "./useNavigateScenarios";

export const StartSimulation = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const { startSimDraft, loading, error } = useNavigateScenarios();

  return (
    <Stack
      flex={1}
      mr={2}
      bgcolor={colors.common.white}
      border={theme.border.primary}
      borderRadius={theme.borderRadius.medium}
      boxShadow={theme.boxShadow.large}
    >
      <Stack flex={1}>
        <Stack padding={5} pb={0} flex={1}>
          <Stack flexDirection="row" alignItems="end">
            <BoltOutlinedIcon
              sx={{ fontSize: 30, marginLeft: -1, marginBottom: "10px" }}
            />
            <Typography
              fontSize="30px"
              fontWeight="bold"
              ml={1}
              color={colors.textColor.dark}
              fontFamily={"massilia-variable"}
            >
              Draft Scenario
            </Typography>
          </Stack>
          <Typography mt={1} mb={5} fontSize="1em">
            Prepare for the Live Draft by running an unlimited number of draft
            scenarios to determine your optimal pre-draft strategy.
          </Typography>
        </Stack>

        <Stack
          p={2}
          bgcolor={GREY_25}
          borderTop={theme.border.primary}
          borderRadius={`0 0 ${theme.borderRadius.medium} ${theme.borderRadius.medium}`}
          direction="row"
          justifyContent="end"
          alignItems="center"
        >
          {loading && (
            <LinearProgress color="primary" sx={{ marginRight: 2, flex: 1 }} />
          )}
          {error && (
            <Typography color="error" mr={3}>
              There was an error starting the simulation. Please try again.
            </Typography>
          )}
          <Button onClick={startSimDraft} disabled={loading || error} data-testid="start-scenario-button">
            Start Scenario
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
