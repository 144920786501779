import { create } from "zustand";

export enum DateFilterOptions {
  ANY = "Any",
  DAY = "Last 24 Hours",
  WEEK = "Last 7 Days",
  MONTH = "Last Month",
  YEAR = "Last Year",
}

interface FiltersState {
  // Filter by Creator
  creators: string[];
  setCreators: (setCreators: string[]) => void;

  // Filter by Date Range
  dateFilter: DateFilterOptions;
  setDateFilter: (dateFilter: DateFilterOptions) => void;
}

export const useFiltersState = create<FiltersState>()((set) => ({
  // Creators
  creators: [],
  setCreators: (creators: string[]) => set(() => ({ creators })),

  // Dates
  dateFilter: DateFilterOptions.ANY,
  setDateFilter: (dateFilter: DateFilterOptions) => set(() => ({ dateFilter })),
}));

export const getStartDate = (dateFilter: DateFilterOptions) => {
  const currentDate = new Date();

  if (dateFilter === DateFilterOptions.DAY) {
    const last24Hours = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
    return last24Hours;
  }
  if (dateFilter === DateFilterOptions.WEEK) {
    const last7Days = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    return last7Days;
  }
  if (dateFilter === DateFilterOptions.MONTH) {
    const lastMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      currentDate.getDate()
    );
    return lastMonth;
  }
  if (dateFilter === DateFilterOptions.YEAR) {
    const lastYear = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return lastYear;
  }

  // fallback
  const last5Years = new Date(
    currentDate.getFullYear() - 5,
    currentDate.getMonth(),
    currentDate.getDate()
  );
  return last5Years;
};
