import { styled } from "@mui/material";
import { DraftScenariosGrid } from "./DraftScenariosGrid";

export const MyDrafts = () => {
  return (
    <>
      <DraftsContainer>
        <DraftScenariosGrid showStatusFilter={false} />
      </DraftsContainer>
    </>
  );
};

export const DraftsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "40px 40px 10px 40px",
  marginTop: "20px",
  marginBottom: "40px",
  backgroundColor: theme.palette.common.white,
  border: theme.border.primary,
  borderRadius: theme.borderRadius.medium,
  boxShadow: theme.boxShadow.large,
}));
