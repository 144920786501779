import _ from "lodash";
import { PermissionFragment } from "../graphql/generated/graphql";

export enum PermissionEnum {
  // Draft Scenarios
  PermissionDraftScenarioReadWrite = "Permissions.DraftScenarios.ReadWrite",

  // Grades
  PermissionGradesRead = "Permissions.Grades.Read",

  // Grade Weights
  PermissionGradeWeightsRead = "Permissions.Weights.Grades.Read",
  PermissionGradeWeightsWrite = "Permissions.Weights.Grades.Write",

  // Positional Importance
  PermissionPosImportanceRead = "Permissions.PosImportance.Read",
  PermissionPosImportanceWrite = "Permissions.PosImportance.Write",

  // Evals
  PermissionEvalWrite = "Permissions.Evaluations.Write",
  PermissionEvalReadSumerGrade = "Permissions.Evaluations.ReadSumerGrade",

  // Roles
  PermissionRolesWrite = "Permissions.Roles.Write",

  // Permissions
  PermissionPermissionsRead = "Permissions.Permissions.Read",
  PermissionPermissionsWrite = "Permissions.Permissions.Write",

  // Users
  PermissionUsersWrite = "Permissions.Users.Write",

  // Contracts
  PermissionContractRead = "Permissions.Contracts.Read",

  // Players
  PermissionPlayersWriteGrade = "Permissions.Players.WriteGrades",
  PermissionPlayersWriteGamesMissed = "Permissions.Players.WriteGamesMissed",

  // Team Needs
  PermissionTeamNeedsRead = "Permissions.TeamNeeds.Read",
  PermissionTeamNeedsWrite = "Permissions.TeamNeeds.Write",
}

export function hasPermission(
  permissions: PermissionFragment[] | undefined,
  permission: string
): boolean {
  if (permissions == undefined) return false;
  const hasPerm = _.some(permissions, function (perm) {
    return perm.name == permission;
  });

  return hasPerm;
}
