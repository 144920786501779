import { LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined";
import { GREY_25 } from "../comparisonSpace/styles";
import { Button } from "../../common/button/Button";
import { useNavigateLiveDraft } from "./useNavigateLiveDraft";

export const StartLiveDraft = () => {
  const theme = useTheme();
  const colors = theme.palette;
  const { handleStart, daysTillDraft, loading, error } = useNavigateLiveDraft();
  const liveDraftLocked = daysTillDraft > 0;

  return (
    <Stack
      flex={1}
      bgcolor={colors.common.white}
      border={theme.border.primary}
      borderRadius={theme.borderRadius.medium}
      boxShadow={theme.boxShadow.large}
    >
      <Stack flex={1}>
        <Stack padding={5} pb={0} flex={1}>
          <Stack
            flexDirection="row"
            alignItems="end"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <SensorsOutlinedIcon
                sx={{
                  fontSize: 30,
                  marginLeft: -1,
                  marginRight: "4px",
                  marginBottom: "6px",
                }}
              />
              <Typography
                fontSize="30px"
                fontWeight="bold"
                ml={1}
                color={colors.textColor.dark}
                fontFamily={"massilia-variable"}
              >
                Live Draft Optimizer
              </Typography>
            </Stack>
            <Typography
              ml={2}
              pb={1.5}
              fontSize="0.875em"
              color={colors.textColor.light}
            >
              {liveDraftLocked && `${daysTillDraft} days until the Draft`}
            </Typography>
          </Stack>
          <Typography mt={1} mb={5} fontSize="1em">
            The Live Draft Optimizer will be your go-to tool for real-time
            recommendations and analysis during the draft.
          </Typography>
        </Stack>

        <Stack
          p={2}
          bgcolor={GREY_25}
          borderTop={theme.border.primary}
          borderRadius={`0 0 ${theme.borderRadius.medium} ${theme.borderRadius.medium}`}
          direction="row"
          justifyContent="end"
          alignItems="center"
        >
          {!liveDraftLocked && (
            <>
              {loading && (
                <LinearProgress
                  color="primary"
                  sx={{ marginRight: 2, flex: 1 }}
                />
              )}
              {error && (
                <Typography color="error" mr={3}>
                  There was an error starting the simulation. Please try again.
                </Typography>
              )}
              <Button onClick={handleStart} disabled={loading || error}>
                Start Draft
              </Button>
            </>
          )}
          {liveDraftLocked && (
            <Button disabled>
              <LockOutlined sx={{ transform: "scale(0.9)", marginRight: 1 }} />
              Unlocked on Draft Day
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
