import { create } from "zustand";
import { clientConfig } from "../../../config/config";

export type TeamNeed = {
  name: string;
  shortName: string;
  listId?: string;
};

interface TeamNeedsState {
  // Club
  selectedClub: string;
  setSelectedClub: (selectedClub: string) => void;

  // Needs
  needs: TeamNeed[];
  setNeeds: (needs: TeamNeed[]) => void;

  changed: boolean;
  setChanged: (changed: boolean) => void;
}

export const useTeamNeedsState = create<TeamNeedsState>()((set) => ({
  // Club
  selectedClub: clientConfig.clubCode,
  setSelectedClub: (selectedClub: string) => set(() => ({ selectedClub })),

  // Needs
  needs: [],
  setNeeds: (needs: TeamNeed[]) => set(() => ({ needs })),

  changed: false,
  setChanged: (changed: boolean) => set(() => ({ changed })),
}));
