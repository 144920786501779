import { useTheme } from "@mui/material";
import { NavBarSection, NavLink } from "./MarvalNavbar";
import { MarvalPage } from "./navigationState";
import HomeIcon from "@mui/icons-material/Home";

export const HomeLink = () => {
  const theme = useTheme();
  return (
    <NavBarSection>
      <NavLink text="Home" page={MarvalPage.HOME}>
        <HomeIcon
          sx={{
            transform: "scale(0.8)",
            color: theme.palette.grey[50],
            mb: 0.2,
          }}
        />
      </NavLink>
    </NavBarSection>
  );
};

export default HomeLink;
