import { Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { AlertTriangleIcon } from "../common/icons/AlertTriangleIcon";
import { useClubIPEditStore } from "./clubIPEditState";
import { Button, ButtonType } from "../common/button/Button";
import { Modal, ModalSize } from "../common/popup/Modal";

export const ExitConfirmationModal = (props: { setPage: () => void }) => {
  const theme = useTheme();
  const colors = theme.palette;

  const [open, setOpen] = useState(false);
  const { showExitConfirmation, setShowExitConfirmation, setEditing } =
    useClubIPEditStore((state) => state);

  useEffect(() => {
    if (showExitConfirmation) {
      setOpen(true);
    }
  }, [showExitConfirmation]);

  const handleClose = (open: boolean) => {
    setOpen(open);
    setShowExitConfirmation(open);
  };

  const handleExitWithoutSaving = () => {
    setOpen(false);
    setShowExitConfirmation(false);
    setEditing(false);
    props.setPage();
  };

  const Header = (
    <Stack flexDirection="row" alignItems="center">
      <AlertTriangleIcon
        sx={{
          width: "20px",
          height: "20px",
          marginRight: 1,
        }}
      />
      <Typography
        fontSize={20}
        fontWeight={500}
        mt={0.5}
        fontFamily={"massilia-variable"}
      >
        Exit Without Saving?
      </Typography>
    </Stack>
  );

  const Body = (
    <Stack mb={1}>
      <Typography fontSize={14}>
        You have unsaved changes on this page. Would you like to leave this page
        without saving?
      </Typography>
    </Stack>
  );

  const Footer = (
    <Stack flexDirection="row" justifyContent="end">
      <Button
        buttonType={ButtonType.Destructive}
        onClick={handleExitWithoutSaving}
        sx={{
          marginRight: 1,
          "&:hover": {
            backgroundColor: colors.error.light,
          },
        }}
      >
        Exit without saving
      </Button>
      <Button
        buttonType={ButtonType.Secondary}
        onClick={() => handleClose(false)}
        sx={{
          marginRight: 2,
          backgroundColor: colors.common.white,
          "&:hover": {
            backgroundColor: colors.common.white,
          },
        }}
      >
        Cancel
      </Button>
    </Stack>
  );

  return (
    <Modal
      trigger={<></>}
      header={Header}
      body={Body}
      open={open}
      setOpen={handleClose}
      footer={Footer}
      modalSize={ModalSize.ExtraSmall}
    />
  );
};
