import { SvgIcon, SxProps } from "@mui/material";
import React from "react";

export const PenPaperEditIcon = (props: { sx?: SxProps }) => {
  return (
    <SvgIcon sx={{ ...props.sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_3690_32087)">
          <path
            d="M9.16602 3.3332H5.66602C4.26588 3.3332 3.56582 3.3332 3.03104 3.60568C2.56063 3.84536 2.17818 4.22782 1.9385 4.69822C1.66602 5.233 1.66602 5.93307 1.66602 7.3332V14.3332C1.66602 15.7333 1.66602 16.4334 1.9385 16.9682C2.17818 17.4386 2.56063 17.821 3.03104 18.0607C3.56582 18.3332 4.26588 18.3332 5.66602 18.3332H12.666C14.0661 18.3332 14.7662 18.3332 15.301 18.0607C15.7714 17.821 16.1538 17.4386 16.3935 16.9682C16.666 16.4334 16.666 15.7333 16.666 14.3332V10.8332M6.66599 13.3332H8.06145C8.4691 13.3332 8.67292 13.3332 8.86474 13.2871C9.0348 13.2463 9.19737 13.179 9.34649 13.0876C9.51468 12.9845 9.65881 12.8404 9.94706 12.5521L17.916 4.5832C18.6064 3.89284 18.6064 2.77355 17.916 2.0832C17.2257 1.39284 16.1064 1.39284 15.416 2.0832L7.44704 10.0521C7.15879 10.3404 7.01466 10.4845 6.91159 10.6527C6.82021 10.8018 6.75287 10.9644 6.71204 11.1345C6.66599 11.3263 6.66599 11.5301 6.66599 11.9378V13.3332Z"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3690_32087">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
