import {
  CircularProgress,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { GradeWeights } from "./GradeWeights";
import { ClubIPGradeGrid } from "./ClubIPGradeGrid";
import { ClubIPGradesSummary } from "./ClubIPGradesSummary";
import { useParentHeightBoundary } from "../../util/useParentHeightBoundary";
import { PermissionEnum } from "../../../utils/perms";
import { useAuthorizePermission } from "../../settings/accessControls/useAuthorizePermission";
import { UnauthorizedContent } from "../../settings/accessControls/UnauthorizedContent";

export const PlayerGrades = () => {
  const theme = useTheme();
  const [scrollRef, scrollHeight] = useParentHeightBoundary();

  const { hasPerm: canReadGrades, loading: gradeReadLoading } =
    useAuthorizePermission(PermissionEnum.PermissionGradesRead);

  const { hasPerm: canReadGradeWeights, loading: gradeWeightReadLoading } =
    useAuthorizePermission(PermissionEnum.PermissionGradeWeightsRead);

  const permsLoading = gradeReadLoading || gradeWeightReadLoading;

  // Checking Perms
  if (permsLoading) {
    return (
      <ScrollableContent ref={scrollRef} style={{ height: scrollHeight }}>
        <Stack flex={1} justifyContent="center" alignItems="center">
          <CircularProgress color="primary" />
        </Stack>
      </ScrollableContent>
    );
  }

  // No Grades Access
  if (!canReadGrades && !permsLoading) {
    return (
      <Stack flex={1} justifyContent="center" alignItems="center">
        <UnauthorizedContent />
      </Stack>
    );
  }

  return (
    <ScrollableContent ref={scrollRef} style={{ height: scrollHeight }}>
      <Header>
        <Stack flex={1} mb={3}>
          {/* Grade Summary. Displays missing club grades */}
          <ClubIPGradesSummary />
          {/* Grade Weights */}
        </Stack>
      </Header>
      {/* Grade Weights */}
      <Stack px="40px">
        {canReadGradeWeights && (
          <Section>
            <Stack flex={1} mb={3}>
              <Typography
                fontSize={18}
                fontWeight={600}
                color={theme.palette.grey[800]}
                textAlign="center"
                fontFamily={"massilia-variable"}
              >
                Grade Weights
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={theme.palette.grey[600]}
                textAlign="center"
              >
                MARVAL calculates Performance Values according to your chosen
                weight distribution preferences.
              </Typography>
              <GradeWeights />
            </Stack>
          </Section>
        )}
        {/* Player Grades */}
        <ClubIPGradeGrid />
      </Stack>
    </ScrollableContent>
  );
};
const Section = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "40px 40px 10px 40px",
  marginTop: "20px",
  marginBottom: "20px",
  border: theme.border.primary,
  borderRadius: theme.borderRadius.medium,
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.boxShadow.medium,
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "20px 0px 0px 20px",
  backgroundColor: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

const ScrollableContent = styled(Stack)(() => ({
  overflowY: "scroll",
  "::-webkit-scrollbar": { width: 0, height: 0 },
  display: "flex",
}));
