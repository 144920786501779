import { Radio, Stack, Typography, useTheme } from "@mui/material";
import RadioUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { DateFilterOptions } from "./filtersState";

export const DateCreatedFilters = (props: {
  filter: DateFilterOptions;
  setFilters: (dateFilter: DateFilterOptions) => void;
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setFilters(event.target.value as DateFilterOptions);
  };

  return (
    <Stack justifyContent="start">
      <Typography
        fontSize={12}
        fontWeight={600}
        color={colors.grey[700]}
        ml={1}
      >
        DATE CREATED
      </Typography>
      <Stack>
        {Object.values(DateFilterOptions).map((option) => (
          <Stack direction="row" alignItems="center" key={option}>
            <Radio
              key={option}
              value={option}
              checked={props.filter === option}
              onChange={handleFilterChange}
              size="small"
              icon={<RadioUncheckedIcon style={{ fill: colors.grey[600] }} />}
              checkedIcon={
                <CheckCircleOutlineIcon style={{ fill: colors.grey[600] }} />
              }
            />
            <Typography fontSize={12} color={colors.grey[600]}>
              {option}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
