import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Grid,
  styled,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import { Button, ButtonType } from "../../common/button/Button";
import { AlertTriangleIcon } from "../../common/icons/AlertTriangleIcon";
import { QuestionMarkCircleIcon } from "../../common/icons/QuestionMarkCircleIcon";

export const AutoAdjustModal = (props: {
  currentTotal: number;
  autoAdjustCallback: () => void;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <InfoLink onClick={() => setOpen(true)}>
        <QuestionMarkCircleIcon
          sx={{ height: "14px", color: "#343A3F", marginTop: "2px" }}
        />
      </InfoLink>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        PaperProps={{ sx: { borderRadius: "8px" } }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ padding: "16px 20px 10px", borderBottom: "1px solid #C1C7CD" }}
        >
          <Grid container spacing="10">
            <Grid item>
              <AlertTriangleIcon sx={{ marginTop: "3px" }} />
            </Grid>
            <Grid item>Auto-Adjust Positional Weights?</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: "#343A3F",
              fontSize: "14px",
              padding: "30px 0 10px 0",
            }}
          >
            <Grid container spacing="12px">
              <Grid item sx={{ fontSize: "14px" }}>
                MARVAL can automatically scale values to equal 100 while
                preserving the relative importance of each position. Total
                weights must equal 100.
              </Grid>
              <Grid item>
                <Grid container spacing="10px" alignItems="center">
                  <Grid item>Current total is:</Grid>
                  <Grid item sx={{ fontSize: "36px", fontWeight: "700" }}>
                    {props.currentTotal}
                  </Grid>
                  <Grid item sx={{ color: "#F04438" }}>
                    {props.currentTotal > 100 ? "TOO HIGH" : "TOO LOW"}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                Would you like MARVAL to adjust these values for you?
              </Grid>
              <Grid item>
                NOTE: Any positions that you have manually adjusted will be
                locked until you save your edits.
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "#F2F4F8",
            borderTop: "1px solid #C1C7CD",
            padding: "15px 25px",
          }}
        >
          <Button
            onClick={() => setOpen(false)}
            buttonType={ButtonType.Secondary}
          >
            Edit Manually
          </Button>
          <Button
            onClick={() => {
              props.autoAdjustCallback();
              setOpen(false);
            }}
            autoFocus
          >
            Auto-Adjust
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const InfoLink = styled(Link)(() => ({
  "&:hover": {
    cursor: "pointer",
  },
}));
