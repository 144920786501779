import _ from "lodash";
import { enforceSum } from "./enforceSum";

function numberEquals(a: number, b: number) {
  return Math.abs(a - b) < 0.005;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUnitValues<T>(values: T[], unit: number) {
  return values.filter((_, i) => i % 2 == unit);
}

/**
 * Utility to use the selectedIndex of the combined array
 * to determine which element of a split-array was selected
 */
export const projectSelectedIndex = (selectedIndex: number, unit: number) => {
  if (selectedIndex % 2 !== unit) {
    return -1;
  }
  if (unit === 0) {
    return selectedIndex / 2;
  }
  return Math.floor(selectedIndex / 2);
};

/**
 * Utility to merge unit sub-arrays back into a combined array
 * Pseudo-inverse of getUnitValues
 */
export const coalesceUnits = (unit0: number[], unit1: number[]) => {
  if (unit0.length !== unit1.length) {
    throw new Error("coalesceUnits requires equal length inputs");
  }
  const rval: number[] = new Array(unit0.length * 2);
  for (let i = 0; i < rval.length; i++) {
    if (i % 2 === 0) {
      rval[i] = unit0[Math.round(i / 2)];
    } else {
      rval[i] = unit1[Math.floor(i / 2)];
    }
  }
  return rval;
};

/**
 * This was the old function we used to guarantee sum-to-100 logic on the old survey
 * It has an incredible number of constraints on it and needs to be re-written
 * @deprecated
 */
export const enforceSumWithLocks = (
  a: number[],
  targetSum: number,
  changedIndex: number,
  isLocked: boolean[],
  tiedIndices: number[],
  unit0Lock?: number,
  unit1Lock?: number
) => {
  if (a.length === 0 || a.length % 2 !== 0) {
    throw new Error("Length of input values must be +even for unit locks");
  }

  // test case for 0lock + 1lock = target
  if ((unit0Lock || 0) + (unit1Lock || 0) > targetSum + 0.0001) {
    throw new Error(
      "Unit locks are greater than target sum, no solution possible"
    );
  }

  const changedUnit = changedIndex % 2;

  if (unit0Lock || unit1Lock) {
    if (!unit0Lock || !unit1Lock) {
      throw new Error("Either none or both units must be locked at a value");
    }
    const unit0 = getUnitValues(a, 0);
    const unit1 = getUnitValues(a, 1);

    const unit0Sum = _.sum(unit0);
    const unit1Sum = _.sum(unit1);

    const unit0Locks = getUnitValues(isLocked, 0);
    const unit1Locks = getUnitValues(isLocked, 1);

    const unit0Selection = projectSelectedIndex(changedIndex, 0);
    const unit1Selection = projectSelectedIndex(changedIndex, 1);

    if (changedUnit === 0 && unit1Lock > unit1Sum + 0.0001) {
      throw new Error(
        "No solution possible. Locked unit does not meet existing constraints"
      );
    }
    if (changedUnit === 1 && unit0Lock > unit0Sum + 0.0001) {
      throw new Error(
        "No solution possible. Locked unit does not meet existing constraints"
      );
    }

    const unit0Ties = tiedIndices
      .filter((a) => a % 2 === 0)
      .map((i) => projectSelectedIndex(i, 0));
    const unit1Ties = tiedIndices
      .filter((a) => a % 2 === 1)
      .map((i) => projectSelectedIndex(i, 1));

    let newUnit0 = unit0;
    if (changedUnit === 0) {
      newUnit0 = enforceSum(
        unit0,
        unit0Lock,
        unit0Selection,
        unit0Locks,
        unit0Ties
      );
    }

    let newUnit1 = unit1;
    if (changedUnit === 1) {
      newUnit1 = enforceSum(
        unit1,
        unit1Lock,
        unit1Selection,
        unit1Locks,
        unit1Ties
      );
    }

    return coalesceUnits(newUnit0, newUnit1);
  } else {
    return enforceSum(a, targetSum, changedIndex, isLocked, tiedIndices);
  }
};
