import { PositionalImportanceMetric } from "../../../graphql/generated/graphql";
import {
  capitalize,
  Grid,
  Slider,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";

export const PositionalWeightGroup = (props: {
  currentGroup: string;
  metrics: PositionalImportanceMetric[];
  positionWeights: string[];
  marketWeights: string[];
  handleSliderChangeCallback: (newValue: number, index: number) => void;
  handleInputChangeCallback: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    validate: boolean
  ) => void;
  isReadOnly: boolean;
  updateSurveyLoading: boolean;
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer sx={{ overflow: "clip" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="35%">
                  <PositionColumnHeader>
                    {capitalize(_.startCase(props.currentGroup))}
                  </PositionColumnHeader>
                  <ColumnHeader>WEIGHT (0.0-40.0)</ColumnHeader>
                </TableCell>
                <TableCell width="10%"></TableCell>
                <TableCell width="45%"></TableCell>
                <TableCell width="10%">
                  <ColumnHeader>MKT value</ColumnHeader>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.metrics.map((metric, index) => {
                if (metric.group == props.currentGroup) {
                  return (
                    <TableRow key={index}>
                      <Position>
                        <Typography>{metric.clubNodeId}</Typography>
                      </Position>
                      <Metric>
                        <StyledTextField
                          type="number"
                          variant={props.isReadOnly ? "standard" : "outlined"}
                          value={props.positionWeights[index]}
                          disabled={props.isReadOnly}
                          onChange={(event) =>
                            props.handleInputChangeCallback(
                              event as React.ChangeEvent<HTMLInputElement>,
                              index,
                              false
                            )
                          }
                          onBlur={(event) =>
                            props.handleInputChangeCallback(
                              event as React.ChangeEvent<HTMLInputElement>,
                              index,
                              true
                            )
                          }
                          InputProps={{
                            inputProps: {
                              step: ".1",
                              style: {
                                padding: "8px 8px 8px 12px",
                                width: "50px",
                                fontSize: "14px",
                                border: "none",
                                fontWeight: "700",
                              },
                            },
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                            "& .MuiInput-root.Mui-disabled:before ": {
                              borderBottomStyle: "none",
                            },
                          }}
                        />
                      </Metric>
                      <Metric>
                        <StyledSlider
                          disabled={props.isReadOnly || props.updateSurveyLoading}
                          min={0}
                          max={40}
                          step={0.1}
                          value={Number(props.positionWeights[index])}
                          onChange={(event, value) =>
                            props.handleSliderChangeCallback(
                              value as number,
                              index
                            )
                          }
                          valueLabelDisplay="auto"
                        />
                      </Metric>
                      <Metric>{props.marketWeights[index]}</Metric>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const PositionColumnHeader = styled(Typography)(() => ({
  fontSize: "18px",
  fontWeight: "600px",
  lineHeight: "28px",
}));

const ColumnHeader = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
}));

const Position = styled(TableCell)(() => ({
  border: "none",
  padding: "4px 15px",
  fontSize: "14px",
}));

const Metric = styled(TableCell)(() => ({
  border: "none",
  padding: "4px 15px",
  fontSize: "14px",
  fontWeight: "700",
  "&.Mui-disabled": {
    color: "#343A3F",
  },
}));

const StyledTextField = styled(TextField)(() => ({}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  margin: 0,
  padding: 0,
  width: "500px",
  height: "8px",
  "&.Mui-disabled": {
    color: theme.palette.grey[400],
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
}));
