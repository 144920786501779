import { Drawer, Stack, Typography, styled, useTheme } from "@mui/material";
import { DateCreatedFilters } from "./DateCreatedFilters";
import { TradesMadeFilters } from "./TradesMadeFilters";
import { CreatedByFilters } from "./CreatedByFilters";
import { DateFilterOptions, useFiltersState } from "./filtersState";
import { useState } from "react";
import { Button, ButtonSize, ButtonType } from "../../../common/button/Button";

export const FiltersDrawer = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();

  const { dateFilter, setCreators, setDateFilter } = useFiltersState(
    (state) => state
  );
  const [currentDateFilter, setCurrentDateFilter] = useState(dateFilter);
  const disabled = currentDateFilter === dateFilter;

  const handleClear = () => {
    setCreators([]);
    setDateFilter(DateFilterOptions.ANY);
    props.onClose();
  };

  const handleApply = () => {
    setDateFilter(currentDateFilter);
    props.onClose();
  };

  return (
    <Drawer
      open={props.open}
      anchor={"right"}
      hideBackdrop={true}
      onClose={props.onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: "30%",
        },
      }}
    >
      <Stack>
        <Section>
          <Typography fontSize={18} fontWeight={600}>
            Filters
          </Typography>
          <Button
            buttonType={ButtonType.Secondary}
            buttonSize={ButtonSize.Small}
            onClick={() => {
              props.onClose();
              handleClear();
            }}
          >
            Cancel
          </Button>
        </Section>

        <Section>
          <Typography fontSize={14} fontWeight={600}>
            Basic
          </Typography>
        </Section>

        <Section>
          <Stack direction="row" flex={1} justifyContent="space-between">
            <CreatedByFilters />
            <DateCreatedFilters
              filter={currentDateFilter}
              setFilters={setCurrentDateFilter}
            />
            <TradesMadeFilters />
          </Stack>
        </Section>
        <Section
          sx={{
            backgroundColor: theme.palette.grey[50],
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Button buttonType={ButtonType.Tertiary} onClick={handleClear}>
            Clear All Filters
          </Button>
          <Button dark disabled={disabled} onClick={handleApply}>
            Apply Filters
          </Button>
        </Section>
      </Stack>
    </Drawer>
  );
};

const Section = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px 12px 16px 12px",
  borderBottom: `2px solid ${theme.palette.grey[100]}`,
}));
