import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Big from "big.js";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PositionalImportanceMetric } from "../../../graphql/generated/graphql";
import { enforceSum } from "../../../utils/survey";
import { Button, ButtonSize, ButtonType } from "../../common/button/Button";
import { PenPaperEditIcon } from "../../common/icons/PenPaperEditIcon";
import { SaveDiskIcon } from "../../common/icons/SaveDiskIcon";
import { useClubIPEditStore } from "../clubIPEditState";
import { useClubIPGradesData } from "../ClubIPGradesDataProvider";
import { AutoAdjustModal } from "./AutoAdjustModal";
import { PositionalWeightGroup } from "./PositionalWeightGroup";

export const PositionalWeights = (props: { canEdit: boolean }) => {
  const [metrics, setMetrics] = useState(
    new Array<PositionalImportanceMetric>()
  );
  const [positionWeights, setPositionWeights] = useState(new Array<string>());
  const [totalWeight, setTotalWeight] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [copyOfPositionWeights, setCopyOfPositionWeights] = useState(
    new Array<string>()
  );

  const { setEditing } = useClubIPEditStore((state) => state);

  const {
    updatePositionalImportance: {
      mutation: updateSurvey,
      loading: updateSurveyLoading,
    },
    getPositionalImportanceSurveyQuery: { data },
  } = useClubIPGradesData();

  const [groups, setGroups] = useState(new Array<string>());
  const [marketWeights, setMarketWeights] = useState(new Array<string>());
  const [lockedIndexes, setLockedIndexes] = useState(new Array<boolean>());

  // initialize data
  useEffect(() => {
    const positionalImportancePage = data?.positionalImportanceSurvey.pages[0];
    const metrics = positionalImportancePage?.metrics || [];
    setMetrics(metrics);
    const groups = Array.from(new Set(metrics.map((item) => item.group)));
    setGroups(groups);
    const positionWeights = metrics.map(
      (metric) => metric.value?.toString() || "0"
    );
    setPositionWeights(positionWeights);
    const marketWeights = roundAndCleanUp(
      metrics.map((metric) => metric.marketDefault),
      metrics.map(() => false)
    );
    setMarketWeights(marketWeights);
  }, [data]);

  // track total weights
  useEffect(() => {
    const newTotalWeight = positionWeights.reduce(
      (pv, cv) => new Big(pv).plus(new Big(cv)),
      new Big(0)
    );
    setTotalWeight(newTotalWeight.toNumber());
    setIsValid(newTotalWeight.eq(100));
  }, [positionWeights]);

  const updateWeight = (newValue: string, index: number) => {
    const updatedPositionWeights = [
      ...positionWeights.slice(0, index),
      newValue,
      ...positionWeights.slice(index + 1),
    ];
    setPositionWeights(updatedPositionWeights);
    addLockedIndex(index);
  };

  const autoAdjust = () => {
    const weightsAsNumbers = positionWeights.map((w) => Number(w));
    const adjustedWeights = enforceSum(
      weightsAsNumbers,
      100,
      -1,
      lockedIndexes,
      new Array<number>()
    );
    const cleanedWeights = roundAndCleanUp(adjustedWeights, lockedIndexes);
    setPositionWeights(cleanedWeights);
  };

  const addLockedIndex = (index: number) => {
    setLockedIndexes([
      ...lockedIndexes.slice(0, index),
      true,
      ...lockedIndexes.slice(index + 1),
    ]);
  };

  const resetLockedIndexes = () => {
    setLockedIndexes(positionWeights.map(() => false));
  };

  // slider change
  const handleSliderChange = (newValue: number, index: number) => {
    updateWeight(newValue.toString(), index);
  };

  // update with validation
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    validate: boolean
  ) => {
    // if the new character is not a number or decimal point, don't allow it
    if (isNaN(Number(event.target.value))) {
      return;
    }

    // they are still typing, don't validate
    if (!validate) {
      updateWeight(event.target.value, index);
      return;
    }

    let newValue = Number(event.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 40) {
      newValue = 40;
    }
    const formattedValue = Number.isInteger(newValue)
      ? newValue.toString()
      : newValue.toFixed(1);
    updateWeight(formattedValue, index);
  };

  const handleEdit = () => {
    setEditing(true);
    setIsReadOnly(false);
    setCopyOfPositionWeights(_.cloneDeep(positionWeights));
    resetLockedIndexes();
  };

  const handleSave = () => {
    const survey = data?.positionalImportanceSurvey;
    const page = data?.positionalImportanceSurvey.pages[0];

    if (survey !== undefined && page !== undefined) {
      const surveyResult = updateSurvey({
        variables: {
          newPage: {
            id: survey.id,
            title: page.title,
            surveyType: page.surveyType,
            metrics: metrics.map((m, index) => ({
              clubNodeId: m.clubNodeId,
              value: Number(positionWeights[index]),
              expertDefault: m.expertDefault,
              marketDefault: m.marketDefault,
              group: m.group,
              id: m.id,
            })),
          },
        },
        refetchQueries: ["GetClubIpAuditAttributes"],
      });

      surveyResult.then(() => {
        setIsReadOnly(true);
        toast.success("Positional Importance changes are now applied", {
          autoClose: 4000,
          position: "bottom-right",
        });
      });
    }
    resetLockedIndexes();
    setEditing(false);
  };

  const setAllMarketValue = () => {
    if (isReadOnly) {
      handleEdit();
    }

    setPositionWeights(marketWeights);
  };

  const handleCancel = () => {
    setEditing(false);
    setPositionWeights(copyOfPositionWeights);
    setIsReadOnly(true);
    resetLockedIndexes();
  };

  return (
    <ContentContainer mt={3} id="content-container">
      <Grid container justifyContent="space-between">
        <PositionWeightsMessageContainer item>
          <PositionWeightsTitle>Position Weights</PositionWeightsTitle>
          <PositionWeightsDescription>
            Assign weights from 0.0 to 40.0 to each position based on their
            respective importance to your roster. Positions can have the same
            weight.
          </PositionWeightsDescription>
        </PositionWeightsMessageContainer>
        <Grid item>
          <TotalWeightsContainer
            container
            justifyContent="center"
            alignContent="center"
            className={isValid ? "" : "invalid"}
          >
            <TotalWeightMessage item xs={6}>
              Total Weights Must Equal 100
            </TotalWeightMessage>
            <TotalWeightNumber item xs={6} className={isValid ? "" : "invalid"}>
              {totalWeight?.toFixed(1)}
            </TotalWeightNumber>
            <Grid item>
              {!isReadOnly && !isValid && props.canEdit && (
                <Grid container spacing="1">
                  <Grid item>
                    <AutoAdjustLink onClick={autoAdjust}>
                      Auto Adjust to 100
                    </AutoAdjustLink>
                  </Grid>
                  <Grid item marginTop="1px">
                    <AutoAdjustModal
                      currentTotal={totalWeight}
                      autoAdjustCallback={autoAdjust}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </TotalWeightsContainer>
        </Grid>
      </Grid>
      <Separator />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container gap="16px">
            <Grid item>Set All Values to:</Grid>
            <Grid item>
              <SetValuesLink onClick={setAllMarketValue}>
                Market Value
              </SetValuesLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {isReadOnly && props.canEdit && (
            <Button onClick={handleEdit} sx={{ borderRadius: "8px" }} data-testid="edit-pos-weight-btn">
              Edit Position Weights
              <PenPaperEditIcon
                sx={{
                  color: "white",
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                  marginTop: "-2px",
                }}
              />
            </Button>
          )}
          {!isReadOnly && props.canEdit && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                onClick={handleCancel}
                buttonType={ButtonType.Secondary}
                buttonSize={ButtonSize.Small}
                disabled={updateSurveyLoading}
              >
                Cancel
              </Button>
              <Button
                disabled={updateSurveyLoading || !isValid}
                onClick={handleSave}
                size={ButtonSize.Small}
              >
                Save
                {updateSurveyLoading ? (
                  <CircularProgress
                    color="primary"
                    size="1.25em"
                    sx={{ marginLeft: 1 }}
                  />
                ) : (
                  <SaveDiskIcon
                    sx={{ width: "20px", height: "20px", marginLeft: "5px" }}
                  />
                )}
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {groups.map((group, idx) => (
          <Grid item key={idx}>
            <PositionalWeightGroup
              currentGroup={group}
              metrics={metrics}
              positionWeights={positionWeights}
              marketWeights={marketWeights}
              handleSliderChangeCallback={handleSliderChange}
              handleInputChangeCallback={handleInputChange}
              isReadOnly={isReadOnly}
              updateSurveyLoading={updateSurveyLoading}
              key={idx}
            />
          </Grid>
        ))}
      </Grid>
    </ContentContainer>
  );
};

// there are values with many decimal place precision, but we need single decimal
// we need to round and then clean up to make sure it's 100
const roundAndCleanUp = (
  weights: number[],
  lockedIndexes: boolean[]
): string[] => {
  const roundedWeights = weights.map((w) => Math.round(w * 10) / 10);
  const total = roundedWeights.reduce(
    (pv, cv) => new Big(pv).plus(new Big(cv)),
    new Big(0)
  );

  if (!total.eq(100)) {
    const firstUnlockedIndex = roundedWeights.findIndex(
      (e, index) => e > 0 && e < 40 && !lockedIndexes[index]
    );

    if (firstUnlockedIndex >= 0) {
      const diff = total.minus(100);
      const weightToChange = new Big(roundedWeights[firstUnlockedIndex]);
      const updatedWeight = weightToChange.minus(new Big(diff));
      roundedWeights[firstUnlockedIndex] = updatedWeight.toNumber();
    }
  }

  return roundedWeights.map((w) => w.toFixed(1));
};

const ContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: "16px",
  boxShadow: theme.boxShadow.medium,
  border: theme.border.primary,
  borderRadius: theme.borderRadius.medium,
}));

const PositionWeightsMessageContainer = styled(Grid)(() => ({
  maxWidth: "580px",
}));

const Separator = styled(Box)(() => ({
  borderBottom: "1px solid #C1C7CD",
  margin: "20px 0px",
}));

const TotalWeightsContainer = styled(Grid)(() => ({
  borderRadius: "8px",
  padding: "0px 20px",
  border: "1px solid #C1C7CD",
  height: "86px",
  boxShadow: "1",
  width: "270px",
  "&.invalid": {
    borderColor: "#F04438",
  },
}));

const TotalWeightMessage = styled(Grid)(() => ({
  textAlign: "center",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
}));

const TotalWeightNumber = styled(Grid)(() => ({
  color: "#669F2A",
  fontSize: "36px",
  fontWeight: "700",
  justifyContent: "center",
  textAlign: "center",
  paddingLeft: "16px",
  "&.invalid": {
    color: "#F04438",
  },
}));

const PositionWeightsTitle = styled(Typography)(() => ({
  color: "#121619",
  fontSize: "18px",
  fontWeight: "800",
  lineHeight: "28px",
  paddingBottom: "4px",
  fontFamily: "massilia-variable",
}));

const PositionWeightsDescription = styled(Typography)(() => ({
  color: "#343A3F",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  paddingBottom: "8px",
}));

const AutoAdjustLink = styled(Link)(() => ({
  fontSize: "12px",
  fontWeight: "700",
  textDecoration: "none",
  "&:hover": {
    cursor: "pointer",
  },
}));

const SetValuesLink = styled(Link)(() => ({
  fontWeight: "700",
  textDecoration: "none",
  "&:hover": {
    cursor: "pointer",
  },
}));
