import { Stack, Typography, useTheme } from "@mui/material";

export const MyRoster = () => {
  const theme = useTheme();
  return (
    <Stack flex={1} justifyContent="center" alignItems="center">
      <Typography
        fontSize={28}
        fontWeight={500}
        color={theme.palette.grey[300]}
      >
        My Roster Page
      </Typography>
    </Stack>
  );
};
