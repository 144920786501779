import { useRef } from "react";
import type { Identifier, XYCoord } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";
import { TeamNeedsCard } from "./TeamNeedsCard";
import { TeamNeed } from "./teamNeedsState";
import { StringOrNumberOrCallback } from "victory";

interface DraggableTeamNeedsCardProps {
  id: StringOrNumberOrCallback;
  position: TeamNeed;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  readOnly: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DraggableTeamNeedsCard = ({
  id,
  position,
  index,
  moveCard,
  readOnly,
}: DraggableTeamNeedsCardProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: TEAM_NEEDS_CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: TEAM_NEEDS_CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (!readOnly) {
    drag(drop(ref));
  }

  return (
    <div ref={ref} data-handler-id={handlerId}>
      <TeamNeedsCard
        position={position}
        isDragging={isDragging}
        readOnly={readOnly}
      />
    </div>
  );
};

export const TEAM_NEEDS_CARD = "TEAM_NEEDS_CARD";
