import { Stack, Typography, useTheme } from "@mui/material";

export const ClubIPGradesSummary = () => {
  const theme = useTheme();

  return (
    <Stack alignItems="left">
      <Stack>
        <Typography
          fontSize={24}
          fontWeight={600}
          color={theme.palette.textColor.dark}
          textAlign="left"
          fontFamily={"massilia-variable"}
        >
          Grades
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={400}
          color={theme.palette.textColor.dark}
          textAlign="left"
          mt={0.5}
        >
          View and edit your grades for both Draft Prospects and Pro Players.
          Adjust the weights of the grades used to calculate Performance Value.
          Changes made to Grades and Grade Weights take effect immediately and
          apply to all users across your entire club.
        </Typography>
      </Stack>
    </Stack>
  );
};
