import { Checkbox, Stack, Typography, useTheme } from "@mui/material";
import {
  useGetMyDraftsQuery,
  useGetUserByIdQuery,
} from "../../../../graphql/generated/graphql";
import { useFiltersState } from "./filtersState";

export const CreatedByFilters = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const { data } = useGetMyDraftsQuery();
  const allCreators = data?.myDraftScenarios
    .filter((row) => row.createdBy !== null && row.createdBy !== undefined)
    .map((row) =>
      row.createdBy !== null && row.createdBy !== undefined ? row.createdBy : ""
    );
  const creatorsUnique: string[] = Array.from(new Set(allCreators));

  const { creators, setCreators } = useFiltersState((state) => state);

  const handleFilterChange = (value: string) => {
    if (creators.includes(value)) {
      setCreators(creators.filter((filter) => filter !== value));
    } else {
      setCreators([...creators, value]);
    }
  };

  return (
    <Stack>
      <Typography
        fontSize={12}
        fontWeight={600}
        color={colors.grey[700]}
        ml={1}
      >
        CREATOR
      </Typography>
      <Stack>
        {creatorsUnique.map((option) => (
          <Stack key={option} direction="row" alignItems="center">
            <Checkbox
              checked={creators.includes(option)}
              onChange={() => handleFilterChange(option)}
              size="small"
              style={{ color: colors.grey[300] }}
              disabled={true} //temp until we can implement this
            />
            <CreatedByCell userId={option} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export const CreatedByCell = (props: { userId: string }) => {
  const theme = useTheme();
  const colors = theme.palette;

  const { data, error } = useGetUserByIdQuery({
    variables: {
      id: props.userId,
    },
  });

  if (!data || error) return null;

  return (
    <Typography fontSize={12} color={colors.grey[300]}>
      {data.userById?.firstName} {data.userById?.lastName}
    </Typography>
  );
};
