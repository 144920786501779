import { TeamNeed } from "./teamNeedsState";

export const OffensePositionSet: TeamNeed[] = [
  {
    name: "Quarterback",
    shortName: "QB",
  },
  {
    name: "Wide Receiver",
    shortName: "WR",
  },
  {
    name: "Running Back",
    shortName: "RB",
  },
  {
    name: "Full Back",
    shortName: "FB",
  },
  {
    name: "Tight End",
    shortName: "TE",
  },
  {
    name: "Offensive Tackle",
    shortName: "OT",
  },
  {
    name: "Offensive Guard",
    shortName: "OG",
  },
  {
    name: "Offensive Center",
    shortName: "OC",
  },
];

export const DefensePositionSet: TeamNeed[] = [
  {
    name: "Defensive End",
    shortName: "DE",
  },
  {
    name: "Defensive Tackle",
    shortName: "DT",
  },
  {
    name: "Outside Linebacker",
    shortName: "OB",
  },
  {
    name: "Inside Linebacker",
    shortName: "IB",
  },
  {
    name: "Outside Linebacker",
    shortName: "OB",
  },
  {
    name: "Strongside Linebacker",
    shortName: "SLB",
  },
  {
    name: "Defensive Corner",
    shortName: "DC",
  },
  {
    name: "Safety",
    shortName: "DS",
  },
  {
    name: "Safety",
    shortName: "S",
  },
  {
    name: "Edge Rusher",
    shortName: "EDGE",
  },
];

export const SpecialTeamsPositionSet: TeamNeed[] = [
  {
    name: "Place Kicker",
    shortName: "PK",
  },
  {
    name: "Punter",
    shortName: "P",
  },
  {
    name: "Long Snapper",
    shortName: "LS",
  },
  {
    name: "Return Specialist",
    shortName: "RS",
  },
  {
    name: "Kickoff Return",
    shortName: "KR",
  },
  {
    name: "Punt Return",
    shortName: "PR",
  },
];

export const positionFullName = (shortName: string): string => {
  const mergedPositionSet: TeamNeed[] = [
    ...OffensePositionSet,
    ...DefensePositionSet,
    ...SpecialTeamsPositionSet,
  ];

  const position = mergedPositionSet.find((pos) => pos.shortName === shortName);
  return position ? position.name : "Unknown";
};
