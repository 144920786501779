import { useState } from "react";
import { Box, Stack, TextField, Typography, useTheme } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { GREY_25 } from "../../draft/comparisonSpace/styles";
import { GradeWeightEnum } from "../../../graphql/generated/graphql";
import { getClubAttributesFromConfig } from "../../../utils/clubs";
import { clientConfig } from "../../../config/config";
import { Modal, ModalSize } from "../../common/popup/Modal";
import { Button, ButtonType } from "../../common/button/Button";

type GradeWeightTotalsProps = {
  sumerScoutWeight: number;
  clubWeight: number;
  sageWeight: number;
  onChange: (value: string, gradeType: GradeWeightEnum) => void;
  loading: boolean;
  readOnly: boolean;
};

export const GradeWeightTotals = (props: GradeWeightTotalsProps) => {
  const theme = useTheme();
  const colors = theme.palette;
  const clubAttributes = getClubAttributesFromConfig(clientConfig.clubCode);

  return (
    <Stack
      flex={1}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {/* Club */}
      <GradeDistribution
        name={clubAttributes?.clubCode ?? "Club"}
        weight={props.clubWeight}
        circleColor={colors.primary.main}
        onChange={props.onChange}
        gradeType={GradeWeightEnum.CLUB}
        loading={props.loading}
        readOnly={props.readOnly}
      />

      {/* Sumer Scout */}
      <GradeDistribution
        name={"Sumer Scout"}
        weight={props.sumerScoutWeight}
        circleColor={colors.warning.main}
        onChange={props.onChange}
        gradeType={GradeWeightEnum.SUMER_SCOUT}
        loading={props.loading}
        readOnly={props.readOnly}
      />

      {/* SAGE */}
      <GradeDistribution
        name={"SAGE"}
        weight={props.sageWeight}
        circleColor={colors.link.main}
        onChange={props.onChange}
        gradeType={GradeWeightEnum.SUMER_ANALYTICS_GRADE_ENSEMBLE}
        loading={props.loading}
        readOnly={props.readOnly}
      />
    </Stack>
  );
};

const GradeDistribution = (props: {
  name: string;
  weight: number;
  circleColor: string;
  gradeType: GradeWeightEnum;
  onChange: (value: string, gradeType: GradeWeightEnum) => void;
  loading: boolean;
  readOnly: boolean;
}) => {
  const theme = useTheme();
  const colors = theme.palette;
  const weightText = `${props.weight < 1000 ? "0" : ""}${(
    props.weight / 100
  ).toFixed(1)}`;

  const [open, setOpen] = useState(false);
  const [weight, setWeight] = useState("0");

  const handleOpen = () => {
    setWeight(weightText);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    props.onChange(weight, props.gradeType);
    handleClose();
  };

  return (
    <Stack flexDirection="row" alignItems="center">
      <CircleIcon
        sx={{
          color: props.circleColor,
          width: 15,
          height: 15,
        }}
      />
      <Typography
        fontSize="14px"
        color={colors.grey[700]}
        sx={{ ml: "5px", mr: "5px" }}
      >
        {props.name}
      </Typography>
      <Box
        border={`1px solid ${colors.grey[100]}`}
        borderRadius={2}
        p={"2px 6px 2px 2px"}
        justifyContent="center"
        alignItems="center"
        bgcolor={GREY_25}
      >
        <Typography
          onClick={handleOpen}
          fontSize="12px"
          fontWeight="500"
          color={colors.grey[800]}
          sx={{ ml: "5px", mt: "2px" }}
        >
          {props.loading ? 0 : weightText}%
        </Typography>
      </Box>
      {!props.readOnly && (
        <Modal
          onEnterKey={() => {
            const canSave = weight != weightText;

            if (canSave) {
              handleSave();
            } else {
              handleClose();
            }
          }}
          trigger={<></>}
          header={
            <Typography fontSize={18} fontWeight={500}>
              Edit Weight
            </Typography>
          }
          body={
            <>
              <TextField
                autoFocus
                margin="dense"
                label={props.name}
                fullWidth
                variant="outlined"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
            </>
          }
          open={open}
          setOpen={setOpen}
          footer={
            <Stack flexDirection="row" justifyContent="end">
              <Button
                buttonType={ButtonType.Secondary}
                onClick={handleClose}
                sx={{ marginRight: 2, backgroundColor: colors.grey[50] }}
              >
                Cancel
              </Button>
              <Button dark onClick={handleSave} disabled={weight == weightText}>
                Save
              </Button>
            </Stack>
          }
          modalSize={ModalSize.Small}
        />
      )}
    </Stack>
  );
};
