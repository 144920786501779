import React, { useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import {
  GridColDef,
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridSortItem,
} from "@mui/x-data-grid-premium";

import { GradeWeightEnum } from "../../../graphql/generated/graphql";

import {
  ClubIPlayerGradeRow,
  getClubIPlayerGradeColumns,
} from "./ClubIPGradeColumns";
import { NetworkStatus } from "@apollo/client";
import { TabStyle, Tabs } from "../../common/tabs/Tabs";
import { GridQuickFilterToolbar } from "../../common/grids/GridQuickFilterToolbar";
import { useClubIPGradesData } from "../ClubIPGradesDataProvider";
import { CURRENT_DRAFT_YEAR } from "@sumer/shared/utils/dates";
import { clientConfig } from "../../../config/config";
import { useDataGridStyles } from "../../common/grids/useDataGridStyles";
import { PositionFilterDrawer } from "../../draft/comparisonSpace/selectedPick/PositionFilterDrawer";
import { useAuthorizePermission } from "../../settings/accessControls/useAuthorizePermission";
import { PermissionEnum } from "../../../utils/perms";

export const ClubIPGradeGrid = () => {
  const apiRef = useGridApiRef();
  const PAGE_SIZE = 50;
  const clubCode = clientConfig.clubCode;

  const [positionFilters, setPositionFilters] = useState<string[]>([]);
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [collegeOnly, setCollegeOnly] = useState(true);
  // RVA isn't shown on the Club Roster tab so don't sort by it initially
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    collegeOnly
      ? { field: "rva", sort: "desc" }
      : { field: "pv", sort: "desc" },
  ]);

  const { hasPerm: canWriteGrades } = useAuthorizePermission(
    PermissionEnum.PermissionPlayersWriteGrade
  );

  const handleSortModelChange = async (model: GridSortItem[]) => {
    setSortModel(model);
  };

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      pinnedColumns: { right: ["editGrade"], left: ["rank", "player"] },
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
  });

  const {
    getRosterValueQuery: currentRosterValueQuery,
    getSearchPlayersQuery: {
      loading: clubRosterLoading,
      data: searchPlayersResponse,
    },
    getGradeWeightPreviewQuery: {
      data: getGradeWeightPreviewData,
      loading: gradeWeightPreviewLoading,
    },
    updatePositionalImportance: { loading: positionalImportanceLoading },
  } = useClubIPGradesData();

  const columns: GridColDef<ClubIPlayerGradeRow>[] =
    getClubIPlayerGradeColumns();
  const tabs = ["Draft Prospects", `${clubCode} Roster`];
  const [currentTab, setCurrentTab] = useState<string>(tabs[0]);
  const handleSetCurrentTab = (tab: string) => {
    setCurrentTab(tab);
    setCollegeOnly(tab === tabs[0]);

    // When moving from College to Pro, if sorting by RVA, switch to PV
    if (collegeOnly) {
      setSortModel(
        sortModel.map((item) =>
          item.field === "rva" ? { field: "pv", sort: item.sort } : item
        )
      );
    }
  };

  interface PlayerNumberMap {
    [key: string]: number;
  }

  const { playerRvaMap, playerRvaRankMap } = useMemo(() => {
    const playerRvaMap: PlayerNumberMap = {};
    const playerRvaRankMap: PlayerNumberMap = {};

    // Build a map of <PlayerId, RVA Dollars> then sort it for rank by RVA
    if (currentRosterValueQuery.data?.currentRosterValue) {
      currentRosterValueQuery.data?.currentRosterValue.forEach((record) => {
        const playerId = record.player.id;
        const dollars = record.rosterValueAdded.dollars;
        playerRvaMap[playerId] = dollars;
      });

      // Rank players by RAV DESC w/tied players a the same rank. This is for $0 RVA players
      let lastValue = 0,
        lastRank = 0;

      Object.entries(playerRvaMap)
        .sort((a, b) => b[1] - a[1]) // Sort RVA values DESC
        .forEach((pair, index) => {
          if (pair[1] !== lastValue) {
            lastRank = index + 1;
            lastValue = pair[1];
          }
          playerRvaRankMap[pair[0]] = lastRank; // Assign same rank for same values
        });
    }

    return { playerRvaMap, playerRvaRankMap };
  }, [currentRosterValueQuery.data?.currentRosterValue]);

  const { playerRvaPreviewMap, playerPvPreviewMap } = useMemo(() => {
    const playerRvaPreviewMap: PlayerNumberMap = {};
    const playerPvPreviewMap: PlayerNumberMap = {};

    if (getGradeWeightPreviewData?.gradeWeightPreview) {
      getGradeWeightPreviewData.gradeWeightPreview.forEach((record) => {
        const playerId = record.playerId;
        const rvaPreview = record.rosterValueAdded.dollars;
        const pvPreview = record.overallGrade.value.gapypocDollars;

        playerRvaPreviewMap[playerId] = rvaPreview;
        playerPvPreviewMap[playerId] = pvPreview;
      });
    }

    return { playerRvaPreviewMap, playerPvPreviewMap };
  }, [getGradeWeightPreviewData?.gradeWeightPreview]);

  // Club Roster tab
  const clubGridRows = useMemo(() => {
    const clubGridRows: ClubIPlayerGradeRow[] = [];

    searchPlayersResponse?.searchPlayers.players.forEach((player) => {
      const playerObj = player.record.player;

      const clubGradeDollar = playerObj?.playerGrade?.avgClubGapypocDollars;
      const sumerScoutGradeDollar =
        playerObj?.playerGrade?.avgSumerScoutGapypocDollars;
      const sageGradeDollar = playerObj?.playerGrade?.avgSageGapypocDollars;

      const clubGrade = playerObj?.playerGrade?.seasons.find(
        (s) =>
          s.season == CURRENT_DRAFT_YEAR && s.gradeType == GradeWeightEnum.CLUB
      )?.value.value;

      const sumerScoutGrade = playerObj?.playerGrade?.seasons.find(
        (s) =>
          s.season == CURRENT_DRAFT_YEAR &&
          s.gradeType == GradeWeightEnum.SUMER_SCOUT
      )?.value.value;

      const sageGrade = playerObj?.playerGrade?.seasons.find(
        (s) =>
          s.season == CURRENT_DRAFT_YEAR &&
          s.gradeType == GradeWeightEnum.SUMER_ANALYTICS_GRADE_ENSEMBLE
      )?.value.value;

      const pv = playerObj?.playerGrade?.value.gapypocDollars;
      const pos = player.record.displayPositionShortName ?? "";

      // Only show matching positions if any are selected in the filter
      if (positionFilters.length > 0 && !positionFilters.includes(pos)) {
        return;
      }

      clubGridRows.push({
        player: playerObj,
        headshotUrl: player.record.headshotUrl,
        firstName: player.record.firstName,
        lastName: player.record.lastName,
        position: player.record.displayPositionShortName,
        college: player.record.schoolName,
        clubGrade: clubGrade,
        clubGradeDollar: clubGradeDollar,
        sumerScoutGrade: sumerScoutGrade,
        sumerScoutGradeDollar: sumerScoutGradeDollar,
        sageGrade: sageGrade,
        sageGradeDollar: sageGradeDollar,
        pv: pv,
        pvPreview: playerPvPreviewMap[playerObj.id] || null,
        rva: null,
        rvaPreview: null,
        rvaRank: null,
        pgm: playerObj.projectedGamesMissed.find(
          (pgm) => pgm.season == CURRENT_DRAFT_YEAR
        ),
      });
    });
    return clubGridRows;
  }, [
    playerPvPreviewMap,
    positionFilters,
    searchPlayersResponse?.searchPlayers.players,
  ]);

  // Handle modifications to Draft Prospect tab data so we re-render on filter or update
  const draftGridRows = useMemo(() => {
    const localRows: ClubIPlayerGradeRow[] = [];

    currentRosterValueQuery.data?.currentRosterValue.forEach((record) => {
      const playerObj = record.player;

      const clubGradeDollar = playerObj?.playerGrade?.avgClubGapypocDollars;
      const sumerScoutGradeDollar =
        playerObj?.playerGrade?.avgSumerScoutGapypocDollars;
      const sageGradeDollar = playerObj?.playerGrade?.avgSageGapypocDollars;

      const clubGrade = playerObj.playerGrade?.seasons.find(
        (s) =>
          s.season == CURRENT_DRAFT_YEAR && s.gradeType == GradeWeightEnum.CLUB
      )?.value.value;

      const sumerScoutGrade = playerObj.playerGrade?.seasons.find(
        (s) =>
          s.season == CURRENT_DRAFT_YEAR &&
          s.gradeType == GradeWeightEnum.SUMER_SCOUT
      )?.value.value;

      const sageGrade = playerObj.playerGrade?.seasons.find(
        (s) =>
          s.season == CURRENT_DRAFT_YEAR &&
          s.gradeType == GradeWeightEnum.SUMER_ANALYTICS_GRADE_ENSEMBLE
      )?.value.value;

      const pv = playerObj.playerGrade?.value.gapypocDollars;

      const pos =
        playerObj.clubGradingPosition?.shortName ??
        playerObj.sumerGeneralPosition?.shortName ??
        "";

      // Only show matching positions if any are selected in the filter
      if (positionFilters.length > 0 && !positionFilters.includes(pos)) {
        return;
      }

      localRows.push({
        player: playerObj,
        headshotUrl: playerObj.headshotUrl,
        firstName: playerObj.firstName,
        lastName: playerObj.lastName,
        position: pos,
        college: playerObj.school?.schoolName,
        clubGrade: clubGrade,
        clubGradeDollar: clubGradeDollar,
        sumerScoutGrade: sumerScoutGrade,
        sumerScoutGradeDollar: sumerScoutGradeDollar,
        sageGrade: sageGrade,
        sageGradeDollar: sageGradeDollar,
        rva: playerRvaMap[playerObj.id] || null,
        rvaPreview: playerRvaPreviewMap[playerObj.id] || null,
        pv: pv,
        pvPreview: playerPvPreviewMap[playerObj.id] || null,
        rvaRank: playerRvaRankMap[playerObj.id] || null,
        pgm: playerObj.projectedGamesMissed.find(
          (pgm) => pgm.season == CURRENT_DRAFT_YEAR
        ),
      });
    });

    return localRows;
    // Update the rendered grid if the RVA data or the filters change
  }, [
    currentRosterValueQuery.data?.currentRosterValue,
    playerRvaMap,
    playerRvaRankMap,
    playerRvaPreviewMap,
    playerPvPreviewMap,
    positionFilters,
  ]);

  const proPos =
    searchPlayersResponse?.searchPlayers.players
      ?.flatMap(
        (p) =>
          p.record.displayPositionShortName ??
          p.record.sumerGeneralPositionShortName ??
          ""
      )
      .filter((item) => item !== undefined && item !== "") || [];

  const collegePos =
    currentRosterValueQuery.data?.currentRosterValue
      ?.flatMap(
        (p) =>
          p.player.clubGradingPosition?.shortName ??
          p.player.sumerGeneralPosition?.shortName ??
          ""
      )
      .filter((item) => item !== undefined && item !== "") || [];

  const uniquePositions = [...new Set([...proPos, ...collegePos])];

  const displayRows = collegeOnly ? draftGridRows : clubGridRows;
  const collegeGridLoading =
    currentRosterValueQuery.loading ||
    currentRosterValueQuery.networkStatus === NetworkStatus.refetch;

  const gridLoading = collegeOnly
    ? gradeWeightPreviewLoading || collegeGridLoading
    : gradeWeightPreviewLoading || clubRosterLoading;

  const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);
  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const columnVisibilityModel = {
    rank: collegeOnly,
    rva: collegeOnly,
    rvaPreview: collegeOnly && getGradeWeightPreviewData != undefined,
    pvPreview: getGradeWeightPreviewData != undefined,
    editGrade: canWriteGrades,
  };

  return (
    <Box style={{ flexGrow: 1, marginBottom: "50px" }}>
      <Stack my={1}>
        <Tabs
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={handleSetCurrentTab}
          style={TabStyle.Secondary}
        />
      </Stack>

      <DataGridPremium
        sx={{
          ...useDataGridStyles(),
          "& .MuiDataGrid-columnHeader:first-child, & .MuiDataGrid-cell:first-child":
            {
              pl: 2,
            },
          mt: "-5px",
        }}
        getRowId={(row) => row.player.id}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        sortingOrder={["desc", "asc", null]}
        sortModel={sortModel}
        onSortModelChange={(model) => handleSortModelChange(model)}
        disableColumnSelector
        disableColumnResize
        disableColumnReorder
        disableColumnMenu
        rows={displayRows}
        rowHeight={PAGE_SIZE}
        rowsPerPageOptions={[15, 25, 50, 100]}
        autoHeight
        loading={gridLoading || positionalImportanceLoading}
        disableDensitySelector
        pagination
        disableSelectionOnClick
        apiRef={apiRef}
        initialState={initialState}
        components={{
          Toolbar: GridQuickFilterToolbar,
        }}
        componentsProps={{
          toolbar: {
            setFilterButtonEl,
            setColumnsButtonEl,
            showQuickFilter: true,
            showColumnEdit: false,
            FilterComponent: (
              <PositionFilterDrawer
                open={filtersOpen}
                setOpen={setFiltersOpen}
                positionFilters={positionFilters}
                setPositionFilters={setPositionFilters}
                top={true}
                filterOptions={uniquePositions}
                loading={gridLoading || positionalImportanceLoading}
              />
            ),
          },
          panel: {
            placement: "left",
            anchorEl: columnsButtonEl || filterButtonEl,
          },
        }}
      />
    </Box>
  );
};
