import { styled } from "@mui/material";
import ReactSlider from "react-slider";

type CompositeWeightSliderProps = {
  onChange: (value: number | readonly number[], index: number) => void;
  sumerScout: {
    weight: number;
    defaultWeight: number;
  };
  club: {
    weight: number;
    defaultWeight: number;
  };
  sage: {
    weight: number;
    defaultWeight: number;
  };
  readOnly: boolean;
};

export const LoadingStateSlider = () => {
  return (
    <StyledSlider
      disabled
      max={10000}
      defaultValue={[3333, 6666]}
      renderTrack={(props, state) => {
        return <StyledTrack {...props} index={state.index} />;
      }}
      renderThumb={(props, state) => {
        return <LoadingStateThumb {...props} index={state.index} />;
      }}
    />
  );
};

export const CompositeWeightSlider = (props: CompositeWeightSliderProps) => {
  const { sumerScout, club, readOnly } = props;

  const valueSlider1 = club.weight;
  const valueSlider2 = valueSlider1 + sumerScout.weight;

  const defaultSlider1 = club.defaultWeight;
  const defaultSlider2 = defaultSlider1 + sumerScout.defaultWeight;

  return (
    <StyledSlider
      disabled={readOnly}
      max={10000}
      step={10}
      defaultValue={[defaultSlider1, defaultSlider2]}
      value={[valueSlider1, valueSlider2]}
      onChange={props.onChange}
      renderTrack={(props, state) => {
        return <StyledTrack {...props} index={state.index} />;
      }}
      renderThumb={(props, state) => {
        return (
          <StyledThumb {...props} index={state.index} readOnly={readOnly} />
        );
      }}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledThumb = styled("div")<any>(({ readOnly }) => ({
  height: "25px",
  width: "25px",
  textAlign: "center",
  backgroundColor: "white",
  border: "1px solid black",
  borderRadius: "50%",
  cursor: readOnly ? "default" : "grab",
}));

const StyledSlider = styled(ReactSlider)(() => ({
  width: "100%",
  height: "25px",
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LoadingStateThumb = styled("div")<any>(() => ({
  height: "25px",
  width: "25px",
  textAlign: "center",
  backgroundColor: "white",
  border: "1px solid black",
  borderRadius: "50%",
  cursor: "grab",
  opacity: "25%",
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledTrack = styled("div")<any>(({ index, theme }) => ({
  top: 0,
  bottom: 0,
  borderRadius: "999px",
  backgroundColor:
    index === 2
      ? theme.palette.link.main
      : index === 1
        ? theme.palette.warning.main
        : theme.palette.primary.main,
}));
