import { SvgIcon, SxProps } from "@mui/material";
import React from "react";

export const AlertTriangleIcon = (props: { sx?: SxProps }) => {
  return (
    <SvgIcon sx={{ ...props.sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M10.0001 7.50019V10.8335M10.0001 14.1669H10.0085M8.84622 3.24329L1.99215 15.0821C1.61198 15.7388 1.42189 16.0671 1.44999 16.3366C1.47449 16.5716 1.59763 16.7852 1.78876 16.9242C2.0079 17.0835 2.38728 17.0835 3.14605 17.0835H16.8542C17.613 17.0835 17.9923 17.0835 18.2115 16.9242C18.4026 16.7852 18.5258 16.5716 18.5503 16.3366C18.5783 16.0671 18.3883 15.7388 18.0081 15.0821L11.154 3.24329C10.7752 2.58899 10.5858 2.26184 10.3387 2.15196C10.1232 2.05612 9.87709 2.05612 9.66154 2.15196C9.41443 2.26184 9.22503 2.58899 8.84622 3.24329Z"
          stroke="currentColor"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
