import { CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { TOTAL_GRADE_WEIGHT } from "./GradeWeights";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const TotalWeight = (props: { total: number; loading?: boolean }) => {
  const theme = useTheme();
  const colors = theme.palette;

  let text = "Perfect!";
  let color = colors.success.dark;
  if (props.total < TOTAL_GRADE_WEIGHT) {
    text = "Too low";
    color = colors.warning.main;
  }
  if (props.total > TOTAL_GRADE_WEIGHT) {
    text = "Too high";
    color = colors.error.main;
  }

  return (
    <Stack justifyContent="start" alignItems="center" flex={1}>
      <CircleOutlinedIcon
        sx={{
          fontSize: 130,
          color: color,
          marginBottom: "-100px",
        }}
      />
      {props.loading && <CircularProgress size={70} />}
      {props.loading && (
        <Typography
          mt={2.5}
          fontSize={16}
          fontWeight={600}
          color={theme.palette.grey[600]}
        >
          Loading ...
        </Typography>
      )}
      {!props.loading && (
        <Typography fontSize={48} fontWeight={600} color={color}>
          {Math.round(props.total / 100)}
        </Typography>
      )}
      {!props.loading && (
        <Typography
          mt={2.5}
          fontSize={16}
          fontWeight={600}
          color={theme.palette.grey[600]}
        >
          {text}
        </Typography>
      )}
    </Stack>
  );
};

export const TotalWeightWarning = (props: {
  total: number;
  loading?: boolean;
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  if (props.loading) {
    return (
      <Stack direction="row" alignItems="center">
        <Typography
          fontSize={12}
          fontWeight={500}
          color={theme.palette.grey[600]}
        >
          Saving ...
        </Typography>
      </Stack>
    );
  }

  if (props.total === TOTAL_GRADE_WEIGHT) return null;

  return (
    <Stack direction="row" alignItems="center">
      <WarningAmberIcon
        sx={{
          fontSize: 20,
          color: colors.warning.main,
          mb: 0.5,
        }}
      />
      <Typography
        ml={0.5}
        fontSize={12}
        fontWeight={500}
        color={theme.palette.grey[600]}
      >
        Weights must add up to 100%
      </Typography>
    </Stack>
  );
};
