import { Typography, useTheme } from "@mui/material";
import { NavBarSection, NavLink } from "./MarvalNavbar";
import { MarvalPage } from "./navigationState";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChecklistIcon from "@mui/icons-material/Checklist";
import BorderColorIcon from "@mui/icons-material/BorderColor";

export const ClubVaultLinks = () => {
  const theme = useTheme();
  return (
    <NavBarSection>
      <Typography
        textTransform="none"
        fontSize={20}
        fontWeight={500}
        mb={1}
        color={theme.palette.grey[50]}
        fontFamily={"massilia-variable"}
      >
        Club Vault
      </Typography>
      <NavLink text="Player Grades" page={MarvalPage.GRADES}>
        <BorderColorIcon
          sx={{
            transform: "scale(0.7)",
            color: theme.palette.grey[50],
            mb: 0.4,
          }}
        />
      </NavLink>
      <NavLink
        text="Positional Importance"
        page={MarvalPage.POSITIONAL_IMPORTANCE}
      >
        <BarChartIcon
          sx={{
            transform: "scale(0.8)",
            color: theme.palette.grey[50],
            mb: 0.3,
          }}
        />
      </NavLink>
      {/* <NavLink text="Financials" page={MarvalPage.FINANCIALS}>
        <LocalAtmIcon
          sx={{
            transform: "scale(0.8)",
            color: theme.palette.grey[50],
            mb: 0.2,
          }}
        />
      </NavLink> */}
      <NavLink text="Team Needs" page={MarvalPage.TEAM_NEEDS}>
        <ChecklistIcon
          sx={{ transform: "scale(0.8)", color: theme.palette.grey[50] }}
        />
      </NavLink>
    </NavBarSection>
  );
};

export default ClubVaultLinks;
