import { getClubId } from "@sumer/shared/utils/clubStaticData";
import { useGetTeamNeedsForClubLazyQuery } from "../../../graphql/generated/graphql";
import { useTeamNeedsState } from "./teamNeedsState";
import { CURRENT_DRAFT_YEAR } from "@sumer/shared/utils/dates";
import { v4 as uuidv4 } from "uuid";
import { positionFullName } from "./positionSet";
import { useCallback } from "react";

export const useResetTeamNeeds = () => {
  const { setNeeds, setChanged } = useTeamNeedsState((state) => state);

  const [lazyQuery, { loading }] = useGetTeamNeedsForClubLazyQuery();

  const resetNeedsForClub = useCallback(
    (clubCode: string) => {
      lazyQuery({
        variables: {
          clubId: getClubId(clubCode) ?? "no-club",
          draftSeason: CURRENT_DRAFT_YEAR,
        },
      }).then((response) => {
        const needs = response.data?.teamNeeds
          ? response.data.teamNeeds[0].positionalNeeds.map((need) => ({
              name: positionFullName(need.sumerGeneralPosition),
              shortName: need.sumerGeneralPosition,
              listId: uuidv4(),
            }))
          : [];

        setNeeds(needs);
        setChanged(false);
      });
    },
    [lazyQuery, setNeeds, setChanged]
  );

  return { resetNeedsForClub, resetLoading: loading };
};
