import { Stack, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { TeamNeed, useTeamNeedsState } from "./teamNeedsState";
import { v4 as uuidv4 } from "uuid";

export const AvailablePositionCard = (props: {
  position: TeamNeed;
  readonly: boolean;
}) => {
  const { position } = props;
  const theme = useTheme();
  const colors = theme.palette;

  const { needs, setNeeds, setChanged } = useTeamNeedsState((state) => state);
  const filled = needs.length >= 8;

  const onAdd = (need: TeamNeed) => {
    setChanged(true);
    const newNeed: TeamNeed = { ...need, listId: uuidv4() };
    if (!filled) {
      setNeeds([...needs, newNeed]);
    } else {
      // If list is full, replace the last item in the list with the new Need
      const needsWithoutLast = needs.slice(0, -1);
      setNeeds([...needsWithoutLast, newNeed]);
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width={220}
      border={theme.border.primary}
      borderRadius={theme.borderRadius.small}
      boxShadow={theme.boxShadow.medium}
      mb={1}
      p={1}
      sx={{
        backgroundColor: "white",
      }}
    >
      <Typography fontSize={12} color={colors.grey[700]} ml={1}>
        {position.name} ({position.shortName})
      </Typography>

      {!props.readonly && (
        <AddIcon
          onClick={() => onAdd(position)}
          sx={{
            cursor: "pointer",
            transform: "scale(0.8)",
            color: colors.grey[500],
            "&:hover": { color: "black" },
          }}
        />
      )}
      {props.readonly && <Stack height={24} />}
    </Stack>
  );
};
