import { create } from "zustand";

interface ClubIPEditState {
  showExitConfirmation: boolean;
  setShowExitConfirmation: (showExitConfirmation: boolean) => void;

  editing: boolean;
  setEditing: (setEditing: boolean) => void;
}

export const useClubIPEditStore = create<ClubIPEditState>()((set) => ({
  showExitConfirmation: false,
  setShowExitConfirmation: (showExitConfirmation: boolean) =>
    set(() => ({ showExitConfirmation })),

  editing: false,
  setEditing: (editing: boolean) => set(() => ({ editing })),
}));
