import { Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { TotalWeightWarning } from "./TotalWeight";
import {
  CompositeWeightSlider,
  LoadingStateSlider,
} from "./CompositeWeightSlider";
import { GradeWeightTotals } from "./GradeWeightTotals";
import { useIntercom } from "react-use-intercom";
import { UPDATED_GRADE_WEIGHTS } from "../../draft/shared/intercomEvents";
import { Button, ButtonSize, ButtonType } from "../../common/button/Button";
import { GradeWeightEnum } from "../../../graphql/generated/graphql";
import { toast } from "react-toastify";
import { useClubIPGradesData } from "../ClubIPGradesDataProvider";
import { SaveDiskIcon } from "../../common/icons/SaveDiskIcon";
import { useClubIPEditStore } from "../clubIPEditState";
import { useAuthorizePermission } from "../../settings/accessControls/useAuthorizePermission";
import { PermissionEnum } from "../../../utils/perms";

export const GradeWeights = () => {
  const { trackEvent } = useIntercom();
  const theme = useTheme();
  const colors = theme.palette;

  const { hasPerm: canEditGradeWeights } = useAuthorizePermission(
    PermissionEnum.PermissionGradeWeightsWrite
  );

  const {
    getGradeWeightsQuery: {
      weightClub: initialClubGrade,
      weightSumerScout: initialSumerScoutGrade,
      weightSage: initialSageGrade,
      loading: gradeWeightsLoading,
    },
    getGradeWeightPreviewQuery: {
      setPreviewWeights,
      reset: resetGradeWeightPreview,
      loading: gradeWeightPreviewLoading,
    },
    useUpdateGradeWeights: {
      mutation: updateGradeWeights,
      loading: updateLoading,
      error: updateError,
    },
  } = useClubIPGradesData();

  const [sumerScoutGrade, setSumerScoutGrade] = useState(
    initialSumerScoutGrade
  );
  const [clubGrade, setClubGrade] = useState(initialClubGrade);
  const [sageGrade, setSageGrade] = useState(initialSageGrade);
  const [canSave, setCanSave] = useState(false);

  const handleUpdateWeights = () => {
    trackEvent(UPDATED_GRADE_WEIGHTS);
    updateGradeWeights(clubGrade, sumerScoutGrade, sageGrade);

    if (!updateError) {
      setCanSave(false);
    }
  };

  const handleCancelWeights = () => {
    setSumerScoutGrade(initialSumerScoutGrade);
    setClubGrade(initialClubGrade);
    setSageGrade(initialSageGrade);

    resetGradeWeightPreview();
  };

  const total = Math.round(sumerScoutGrade + clubGrade + sageGrade);

  const hasError =
    !gradeWeightsLoading &&
    (updateError ||
      initialSumerScoutGrade == -1 ||
      initialClubGrade == -1 ||
      initialSageGrade == -1);
  const gradesChanged =
    sumerScoutGrade != initialSumerScoutGrade ||
    clubGrade != initialClubGrade ||
    sageGrade != initialSageGrade;

  const { setEditing } = useClubIPEditStore((state) => state);
  useEffect(() => {
    setEditing(gradesChanged);
  }, [gradesChanged, setEditing]);

  // Set initial weights
  useEffect(() => {
    setSumerScoutGrade(initialSumerScoutGrade);
    setClubGrade(initialClubGrade);
    setSageGrade(initialSageGrade);
  }, [initialSumerScoutGrade, initialClubGrade, initialSageGrade]);

  // Reset grade weight preview (GWP) data on component unmount
  // We don't want to persist grade weight preview data when we leave the page
  useEffect(() => {
    return () => {
      resetGradeWeightPreview();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Manage enabled/disabled Save Button
  useEffect(() => {
    if (gradesChanged) {
      if (total === TOTAL_GRADE_WEIGHT) {
        setCanSave(true);
      } else {
        setCanSave(false);
      }
    } else {
      setCanSave(false);
    }
  }, [total, gradesChanged]);

  const onGradeWeightSliderChange = (
    value: number | readonly number[],
    index: number
  ) => {
    const [lowerIndex, upperIndex] = value as number[];
    const clubGrade = lowerIndex;
    const sumerScoutGrade = upperIndex - lowerIndex;
    const sageGrade = TOTAL_GRADE_WEIGHT - upperIndex;
    if (index === 0) {
      setClubGrade(clubGrade);
      setSumerScoutGrade(sumerScoutGrade);
    } else if (index === 1) {
      setSumerScoutGrade(sumerScoutGrade);
      setSageGrade(sageGrade);
    }

    setPreviewWeights({
      weightClub: clubGrade,
      weightSumerScout: sumerScoutGrade,
      weightSage: sageGrade,
    });
  };

  const onGradeWeightTotalChange = (
    value: string,
    gradeType: GradeWeightEnum
  ) => {
    let valueNumber = parseFloat(value);
    if (isNaN(valueNumber)) {
      toast.error("Grade Weights must be a valid number", {
        autoClose: 4000,
        position: "bottom-right",
      });
      return;
    }

    if (valueNumber >= 100) valueNumber = 100;
    if (valueNumber <= 0) valueNumber = 0;
    valueNumber *= 100;

    // One grade will be set to the incoming grade value so we init them all then override the others
    let newScoutWeight: number = valueNumber,
      newClubWeight: number = valueNumber,
      newSageWeight: number = valueNumber;

    // Adjust grades while maintaining the ratio of the two unchanged grades
    if (gradeType === GradeWeightEnum.SUMER_SCOUT) {
      const totalRatio = clubGrade + sageGrade;
      newClubWeight = Math.round(
        (clubGrade / totalRatio) * (TOTAL_GRADE_WEIGHT - valueNumber)
      );
      newSageWeight = TOTAL_GRADE_WEIGHT - valueNumber - newClubWeight;
    } else if (gradeType === GradeWeightEnum.CLUB) {
      const totalRatio = sumerScoutGrade + sageGrade;
      newScoutWeight = Math.round(
        (sumerScoutGrade / totalRatio) * (TOTAL_GRADE_WEIGHT - valueNumber)
      );
      newSageWeight = TOTAL_GRADE_WEIGHT - valueNumber - newScoutWeight;
    } else if (gradeType === GradeWeightEnum.SUMER_ANALYTICS_GRADE_ENSEMBLE) {
      const totalRatio = sumerScoutGrade + clubGrade;
      newClubWeight = Math.round(
        (clubGrade / totalRatio) * (TOTAL_GRADE_WEIGHT - valueNumber)
      );
      newScoutWeight = TOTAL_GRADE_WEIGHT - valueNumber - newClubWeight;
    }

    setClubGrade(newClubWeight);
    setSumerScoutGrade(newScoutWeight);
    setSageGrade(newSageWeight);

    setPreviewWeights({
      weightClub: newClubWeight,
      weightSumerScout: newScoutWeight,
      weightSage: newSageWeight,
    });
  };

  if (hasError) {
    return (
      <Stack>
        <Stack mt={5} flex={5}>
          <Typography fontWeight={500} fontSize={18} color={colors.error.main}>
            Invalid grade weights. Unable to configure weights.
            <br />
            {updateError?.message}.
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack flexDirection="row" justifyContent="space-between" ml={3}>
      {/* Flex container */}
      <Stack flexBasis={"25%"} justifyContent="end" />

      {/* Weight Slider */}
      <Stack mt={5} flexBasis={"50%"} data-testid="player-grades-weight-slider" >
        {gradeWeightsLoading && <LoadingStateSlider />}
        {!gradeWeightsLoading && (
          <CompositeWeightSlider
            readOnly={!canEditGradeWeights}
            onChange={onGradeWeightSliderChange}
            sumerScout={{
              weight: sumerScoutGrade,
              defaultWeight: gradeWeightsLoading
                ? 3334
                : initialSumerScoutGrade,
            }}
            club={{
              weight: clubGrade,
              defaultWeight: gradeWeightsLoading ? 3334 : initialClubGrade,
            }}
            sage={{
              weight: sageGrade,
              defaultWeight: gradeWeightsLoading ? 3334 : initialSageGrade,
            }}
          />
        )}

        <Stack mt={5}>
          <GradeWeightTotals
            readOnly={!canEditGradeWeights}
            sumerScoutWeight={sumerScoutGrade}
            clubWeight={clubGrade}
            sageWeight={sageGrade}
            onChange={onGradeWeightTotalChange}
            loading={gradeWeightsLoading}
          />
        </Stack>
      </Stack>

      {/* Update Weights */}
      {canEditGradeWeights && (
        <Stack flexBasis={"25%"} alignItems="end" justifyContent="end">
          <TotalWeightWarning total={total} loading={updateLoading} />
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              onClick={handleCancelWeights}
              disabled={
                !gradesChanged || updateLoading || gradeWeightPreviewLoading
              }
              buttonType={ButtonType.Secondary}
              buttonSize={ButtonSize.Small}
              data-testid="cancel-grade-weights-btn"
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpdateWeights}
              disabled={!canSave || updateLoading || gradeWeightPreviewLoading}
              size={ButtonSize.Small}
              data-testid="save-grade-weights-btn"
            >
              Save
              <SaveDiskIcon
                sx={{ width: "20px", height: "20px", marginLeft: "5px" }}
              />
            </Button>
          </Stack>
        </Stack>
      )}
      {!canEditGradeWeights && (
        <Stack flexBasis={"25%"} alignItems="end" justifyContent="end" />
      )}
    </Stack>
  );
};

export const TOTAL_GRADE_WEIGHT = 10000;
