import _ from "lodash";

function numberEquals(a: number, b: number) {
  return Math.abs(a - b) < 0.005;
}

/**
 * This was the old function we used to guarantee sum-to-100 logic on the old survey
 * It has an incredible number of constraints on it and needs to be re-written
 */
export const enforceSum = (
  a: number[],
  targetSum: number,
  changedIndex: number,
  isLocked: boolean[],
  tiedIndices: number[]
) => {
  // console.log("enforceSum.call", {
  //   a,
  //   targetSum,
  //   changedIndex,
  //   isLocked,
  //   tiedIndices,
  // });
  let fill: number;
  const n = a.length;
  const lockedSum = _.sum(
    a.filter((_, i) => changedIndex === i || isLocked[i])
  );

  const numUnlocked = isLocked.filter(
    (a, idx) => changedIndex !== idx && a === false
  ).length;

  if (!numberEquals(lockedSum, targetSum) && numUnlocked === 0) {
    // We aren't at the sum but every value is locked
    throw new Error("locked values prohibit reaching of target sum");
  }

  if (a.length !== isLocked.length) {
    throw new Error("isLocked and input data must be matching lengths");
  }

  const out = [...a];
  tiedIndices.forEach((i) => {
    out[i] = out[changedIndex];
  });
  const numChanged = 1 + tiedIndices.length;
  if (_.sum(isLocked) >= n - numChanged) {
    // TODO: refactor duplicate code
    const fill = (targetSum - lockedSum) / numChanged;
    out[changedIndex] = fill;
    tiedIndices.map((i) => {
      out[i] = fill;
    });
    return out;
  }

  let fixedSum = lockedSum;
  if (changedIndex > -1 && !isLocked[changedIndex]) {
    fixedSum += a[changedIndex] * tiedIndices.length;
  }

  if (fixedSum > targetSum) {
    const otherLockedValsSum = _.sum(a.filter((_, i) => isLocked[i]));

    isLocked.forEach((lock, i) => {
      if (!lock) {
        out[i] = 0;
      }
    });

    const fill = Math.max(targetSum - otherLockedValsSum, 0) / numChanged;
    out[changedIndex] = fill;
    tiedIndices.forEach((i) => {
      out[i] = fill;
    });
    return out;
  }

  const slack = targetSum - fixedSum;
  const flexIndices = _.range(n).filter(
    (i) => !isLocked[i] && i != changedIndex && !tiedIndices.includes(i)
  );
  const flexSum = _.sum(flexIndices.map((i) => a[i]));
  if (flexSum != 0) {
    const scaleFactor = slack / flexSum;
    flexIndices.forEach((i) => {
      out[i] *= scaleFactor;
    });
  } else {
    fill = slack / flexIndices.length;
    flexIndices.forEach((i) => {
      out[i] = fill;
    });
  }
  // console.assert(Math.abs(_.sum(out) - targetSum) < .0001)

  return out;
};
