import { Box, Stack, Typography, styled, useTheme } from "@mui/material";
import { ClubLogoImage } from "@sumer/shared/components/design/ClubLogoImage";
import {
  ClubInformation,
  clubInformation,
} from "@sumer/shared/utils/clubStaticData";
import _ from "lodash";
import { useState } from "react";
import { TeamNeed, useTeamNeedsState } from "./teamNeedsState";
import { useGetTeamNeedsForClubQuery } from "../../../graphql/generated/graphql";
import { positionFullName } from "./positionSet";
import { v4 as uuidv4 } from "uuid";
import { CURRENT_DRAFT_YEAR } from "../../../utils/dates";

export const ClubSelection = () => {
  const theme = useTheme();

  const { selectedClub, setSelectedClub } = useTeamNeedsState((state) => state);

  const clubs = _.orderBy(clubInformation, [(c) => c.division]);
  const divisions = _.groupBy(clubs, "division");

  return (
    <Stack>
      <Stack
        mt={4}
        direction="row"
        alignItems="center"
        flex={1}
        justifyContent="space-around"
      >
        {/* NFC */}
        <Stack direction="row" alignItems="center">
          <Typography
            color={theme.palette.grey[700]}
            fontSize={"20px"}
            fontWeight={700}
            mr={3}
          >
            NFC
          </Typography>
          {Object.entries(divisions)
            .filter(([division]) => division.includes("NFC"))
            .map(([division, clubs]) => (
              <Stack alignItems="center" key={division} mr={5}>
                <Box>
                  <Typography
                    color={theme.palette.grey[600]}
                    fontSize={"14px"}
                    fontWeight={400}
                  >
                    {division}
                  </Typography>
                </Box>
                <Stack>
                  {clubs.map((c) => (
                    <div key={c.clubId}>
                      <Club
                        club={c}
                        selected={selectedClub}
                        setSelected={setSelectedClub}
                      />
                    </div>
                  ))}
                </Stack>
              </Stack>
            ))}
        </Stack>

        {/* AFC */}
        <Stack direction="row" alignItems="center">
          <Typography
            color={theme.palette.grey[700]}
            fontSize={"20px"}
            fontWeight={700}
            mr={3}
          >
            AFC
          </Typography>
          {Object.entries(divisions)
            .filter(([division]) => division.includes("AFC"))
            .map(([division, clubs]) => (
              <Stack alignItems="center" key={division} mr={5}>
                <Box>
                  <Typography
                    color={theme.palette.grey[600]}
                    fontSize={"14px"}
                    fontWeight={500}
                  >
                    {division}
                  </Typography>
                </Box>
                <Stack>
                  {clubs.map((c) => (
                    <div key={c.clubId}>
                      <Club
                        club={c}
                        selected={selectedClub}
                        setSelected={setSelectedClub}
                      />
                    </div>
                  ))}
                </Stack>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const Club = (props: {
  club: ClubInformation;
  selected?: string;
  setSelected: (club: string) => void;
}) => {
  const theme = useTheme();
  const colors = theme.palette;
  const { club } = props;
  const isSelected = props.selected === club.clubCode;
  const { setNeeds, setChanged } = useTeamNeedsState((state) => state);

  const [isHovered, setIsHovered] = useState(false);

  const { data, refetch } = useGetTeamNeedsForClubQuery({
    variables: {
      clubId: club.clubId,
      draftSeason: CURRENT_DRAFT_YEAR,
    },
    fetchPolicy: "network-only",
  });

  const edited = data?.teamNeeds && !data?.teamNeeds[0].isSumerDefault;

  const bgColor = isSelected
    ? colors.primary.main
    : isHovered
    ? colors.grey[400]
    : edited
    ? colors.grey[200]
    : colors.common.white;

  const textColor = isSelected
    ? colors.grey[100]
    : edited || isHovered
    ? colors.grey[800]
    : colors.grey[600];

  const border = theme.border.primary;

  const handleClick = () => {
    refetch();
    props.setSelected(club.clubCode);
    setChanged(false);
    const needs: TeamNeed[] = data?.teamNeeds
      ? data?.teamNeeds[0].positionalNeeds.map((need) => {
          const teamNeed: TeamNeed = {
            name: positionFullName(need.sumerGeneralPosition),
            shortName: need.sumerGeneralPosition,
            listId: uuidv4(),
          };
          return teamNeed;
        })
      : [];
    setNeeds(needs);
  };

  return (
    <Box
      mt={1}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{ cursor: isHovered ? "pointer" : "default" }}
      onClick={handleClick}
    >
      <CircleShadow color={bgColor} border={border}>
        <ClubLogoImage clubCode={club.clubCode ?? ""} height={18} />
        <Typography color={textColor} fontSize={12} fontWeight={500} ml={1}>
          {club.clubCode}
        </Typography>
      </CircleShadow>
    </Box>
  );
};

const CircleShadow = styled(Stack)<{ color: string; border: string }>(
  ({ color, border }) => ({
    borderRadius: "25px",
    padding: "4px",
    flexDirection: "row",
    width: 75,
    paddingLeft: 12,
    paddingRight: 12,
    border: border,
    backgroundColor: color,
    display: "flex",
    justifyContent: "center",
  })
);
