"use client";

import {
  createContext,
  useContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import {
  GetCurrentRosterValueQuery,
  GetPositionalImportanceSurveyQuery,
  SearchPlayersQuery,
  useGetCurrentRosterValueQuery,
  useGetPositionalImportanceSurveyQuery,
  useOverridePlayerGradeMutation,
  useSaveProjectedGamesMissedMutation,
  useSearchPlayersQuery,
  useUpdatePositionalImportanceSurveyMutation,
  GetGradeWeightPreviewQuery,
  useGetGradeWeightPreviewQuery,
  GradeWeightEnum,
  useUpdateGradeWeightMutation,
  UpdateGradeWeightMutation,
  useGetGradeWeightsQuery,
} from "../../graphql/generated/graphql";
import { CURRENT_DRAFT_YEAR } from "@sumer/shared/utils/dates";
import { getClubId } from "@sumer/shared/utils/clubStaticData";
import { clientConfig } from "../../config/config";
import { ApolloError, FetchResult, NetworkStatus } from "@apollo/client";
import { toast } from "react-toastify";
import { debounce } from "lodash";

const CLUB_CODE = clientConfig.clubCode;
const CLUB_ID = getClubId(CLUB_CODE) ?? "";
const seasonRange = 3;
const SEASONS = Array.from(
  { length: seasonRange },
  (_, i) => CURRENT_DRAFT_YEAR + i
);

export const ClubIPGradesDataContext = createContext<{
  // Returns players w/their Roster Value Added (RVA)
  // Used by Player Grades/Draft Prospects for evaluating college players
  getRosterValueQuery: {
    data: GetCurrentRosterValueQuery | undefined;
    loading: boolean;
    error: ApolloError | undefined;
    networkStatus: NetworkStatus;
  };
  // Returns players from the [players] Azure AI index
  // Used by Player Grades/Club Roster for evaluating pro players
  getSearchPlayersQuery: {
    data: SearchPlayersQuery | undefined;
    loading: boolean;
    error: ApolloError | undefined;
    networkStatus: NetworkStatus;
  };
  // Overrides an individual player's club grade
  // Triggers a refetch for getRosterValueQuery() since club grade affects RVA
  overridePlayerGrade: {
    mutation: ReturnType<typeof useOverridePlayerGradeMutation>[0];
    loading: boolean;
    error: ApolloError | undefined;
  };
  // Overrides an individual player's projected games missed
  // Triggers a refetch for getRosterValueQuery() since PGM affects RVA
  savePgm: {
    mutation: ReturnType<typeof useSaveProjectedGamesMissedMutation>[0];
    loading: boolean;
    error: ApolloError | undefined;
  };
  // Returns position importance data
  // Used by PositionalImportance to display current position weights
  getPositionalImportanceSurveyQuery: {
    data: GetPositionalImportanceSurveyQuery | undefined;
    loading: boolean;
    error: ApolloError | undefined;
  };
  // Updates the positional importance survey
  // Triggers a refetch for getRosterValueQuery() since PI affects RVA
  updatePositionalImportance: {
    mutation: ReturnType<typeof useUpdatePositionalImportanceSurveyMutation>[0];
    loading: boolean;
    error: ApolloError | undefined;
  };
  // Returns current grade weights saved in the DB
  getGradeWeightsQuery: {
    weightClub: number;
    weightSumerScout: number;
    weightSage: number;
    loading: boolean;
    error: ApolloError | undefined;
  };
  // Returns players with grade and RVA data using the preview grade weights that are set if any
  getGradeWeightPreviewQuery: {
    data: GetGradeWeightPreviewQuery | undefined;
    loading: boolean;
    error: ApolloError | undefined;
    setPreviewWeights: Dispatch<
      SetStateAction<{
        weightClub: number;
        weightSumerScout: number;
        weightSage: number;
      }>
    >;
    // Resets current grade weights. Useful for clearing out preview data
    reset: () => void;
  };
  // Updates the grade weights saved to the DB. BE recalculates all grades before returning success
  useUpdateGradeWeights: {
    mutation: (
      weightClub: number,
      weightSumerScout: number,
      weightSage: number
    ) => Promise<FetchResult<UpdateGradeWeightMutation>>;
    loading: boolean;
    error: ApolloError | undefined;
  };
} | null>(null);

/* TODO:
      BUGFIX: doesn't calc PV for the club roster because we're not passing club players into GWP
                ... Pass in both college and pro to calc new PV. Will this alter RVA though?
                ... If it will, we need to make a new query that just gets PV and re-query the club roster with that in mind
*/

export const ClubIPDataProvider = ({ children }: { children: ReactNode }) => {
  const {
    loading,
    error,
    data,
    refetch: rosterValueRefetch,
    networkStatus,
  } = useGetCurrentRosterValueQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      request: {
        currentSeason: CURRENT_DRAFT_YEAR,
        seasons: SEASONS,
        clubId: CLUB_ID,
      },
    },
    onCompleted: () => {
      setRosterValueData(data);
    },
  });
  const [rosterValueDataCache, setRosterValueData] = useState(data);

  const {
    loading: searchPlayersLoading,
    error: searchPlayersError,
    data: searchPlayersData,
    networkStatus: searchPlayersNetworkStatus,
  } = useSearchPlayersQuery({
    variables: {
      searchTerm: "",
      options: {
        includeTotalCount: true,
        pageSize: 500,
      },
      filters: {
        collegeOnly: false,
        proOnly: true,
        clubCode: CLUB_CODE,
      },
    },
    onCompleted: () => {
      setSearchPlayersData(searchPlayersData);
    },
  });
  const [searchPlayersDataCache, setSearchPlayersData] =
    useState(searchPlayersData);

  const [
    overrideGradeMutation,
    { loading: overrideGradeLoading, error: overrideGradeError },
  ] = useOverridePlayerGradeMutation({
    onCompleted: (response) => {
      const playerId = response.overridePlayerGrade?.playerId;

      const newPV = response.overridePlayerGrade?.value;
      const newPVGapypoc = newPV?.gapypoc || 0;
      const newPVDollars = newPV?.gapypocDollars || 0;

      const newGrade = response.overridePlayerGrade?.seasons.find(
        (s) =>
          s.season == CURRENT_DRAFT_YEAR && s.gradeType == GradeWeightEnum.CLUB
      );
      const newGradeValue = newGrade?.value.value || "";
      const newGradeGapypoc = newGrade?.value.gapypoc || 0;
      const newGradeDollars = newGrade?.value.gapypocDollars || 0;

      const clubDollars =
        response.overridePlayerGrade?.avgClubGapypocDollars || 0;

      // TODO: Make it so Club Roster data changes don't trigger a RV query. Need to identify if we're changing pro vs college

      // Update the club and WPG/PV grades in our local so the client is consistent even if the BE message bus hasn't propagated
      if (searchPlayersDataCache) {
        const updatedPlayers = searchPlayersDataCache.searchPlayers.players.map(
          (playerRecord) => {
            if (playerRecord.record.player.id === playerId) {
              const updatedPlayerGrade = {
                ...playerRecord.record.player.playerGrade,
                playerId: playerId,
                avgClubGapypocDollars: clubDollars,
                avgSageGapypocDollars:
                  playerRecord.record.player.playerGrade
                    ?.avgSageGapypocDollars || 0,
                avgSumerScoutGapypocDollars:
                  playerRecord.record.player.playerGrade
                    ?.avgSumerScoutGapypocDollars || 0,
                value: {
                  ...playerRecord.record.player.playerGrade?.value,
                  value: "",
                  gapypoc: newPVGapypoc,
                  gapypocDollars: newPVDollars,
                },
                seasons:
                  playerRecord.record.player.playerGrade?.seasons.map(
                    (season) => {
                      if (
                        season.season === CURRENT_DRAFT_YEAR &&
                        season.gradeType === GradeWeightEnum.CLUB
                      ) {
                        return {
                          ...season,
                          value: {
                            value: newGradeValue,
                            gapypoc: newGradeGapypoc,
                            gapypocDollars: newGradeDollars,
                          },
                        };
                      }
                      return season;
                    }
                  ) || [],
              };

              return {
                ...playerRecord,
                record: {
                  ...playerRecord.record,
                  player: {
                    ...playerRecord.record.player,
                    playerGrade: updatedPlayerGrade,
                  },
                },
              };
            }
            return playerRecord;
          }
        );

        setSearchPlayersData({
          ...searchPlayersDataCache,
          searchPlayers: {
            ...searchPlayersDataCache.searchPlayers,
            players: updatedPlayers,
          },
        });
      }

      // RVA is affected by grades so we need to refetch
      rosterValueRefetch();
      toast.success("Club grade set", {
        autoClose: 4000,
        position: "bottom-right",
      });
    },
    onError: () => {
      toast.error("Error when overriding club grade", {
        autoClose: 4000,
        position: "bottom-right",
      });
    },
  });

  const [savePmgMutation, { loading: savePgmLoading, error: savePgmError }] =
    useSaveProjectedGamesMissedMutation({
      onCompleted: (response) => {
        const pgmId = response.saveProjectedGamesMissed?.id;
        const newPgm =
          response.saveProjectedGamesMissed?.projectedGamesMissedOverride;
        // Update the player PGM in our local so the client is consistent even if the BE message bus hasn't propagated
        if (searchPlayersDataCache) {
          const updatedPlayers =
            searchPlayersDataCache.searchPlayers.players.map((playerRecord) => {
              const updatedPgm =
                playerRecord.record.player.projectedGamesMissed.map((pgm) =>
                  pgm.id === pgmId
                    ? {
                        ...pgm,
                        value: Number(newPgm),
                        projectedGamesMissedOverride: newPgm,
                      }
                    : pgm
                );

              return {
                ...playerRecord,
                record: {
                  ...playerRecord.record,
                  player: {
                    ...playerRecord.record.player,
                    projectedGamesMissed: updatedPgm,
                  },
                },
              };
            });

          setSearchPlayersData({
            ...searchPlayersDataCache,
            searchPlayers: {
              ...searchPlayersDataCache.searchPlayers,
              players: updatedPlayers,
            },
          });
        }

        // RVA is affected by PGM so we need to refetch
        rosterValueRefetch();
        toast.success("Projected games missed set", {
          autoClose: 4000,
          position: "bottom-right",
        });
      },
      onError: () => {
        toast.error("Error when saving projected games missed", {
          autoClose: 4000,
          position: "bottom-right",
        });
      },
    });

  const {
    loading: piLoading,
    error: piError,
    data: piData,
  } = useGetPositionalImportanceSurveyQuery({});

  const [
    updateSurvey,
    { loading: updateSurveyLoading, error: updateSurveyError },
  ] = useUpdatePositionalImportanceSurveyMutation({
    onCompleted: () => {
      // RVA is affected by PI so we need to refetch
      rosterValueRefetch();
    },
  });

  const {
    loading: gradeWeightLoading,
    error: gradeWeightError,
    data: gradeWeightData,
  } = useGetGradeWeightsQuery({
    onCompleted: () => {
      const gradeWeights = gradeWeightData?.gradeWeights || [];

      const sumerScoutGrade = gradeWeights?.find(
        (w) => w.gradeWeightType == GradeWeightEnum.SUMER_SCOUT
      );
      const initialSumerScoutGrade = sumerScoutGrade
        ? Math.round(sumerScoutGrade.value)
        : -1;

      const clubGrade = gradeWeights?.find(
        (w) => w.gradeWeightType == GradeWeightEnum.CLUB
      );
      const initialClubGrade = clubGrade ? Math.round(clubGrade.value) : -1;

      const sage = gradeWeights?.find(
        (w) =>
          w.gradeWeightType == GradeWeightEnum.SUMER_ANALYTICS_GRADE_ENSEMBLE
      );
      const initialSageGrade = sage ? Math.round(sage.value) : -1;

      setGradeWeightData({
        weightClub: initialClubGrade,
        weightSumerScout: initialSumerScoutGrade,
        weightSage: initialSageGrade,
      });
    },
  });
  const [gradeWeightDataCache, setGradeWeightData] = useState({
    weightClub: -1,
    weightSumerScout: -1,
    weightSage: -1,
  });

  const [gradeWeightPreviewWeightsDataCache, setGradeWeightPreviewWeightData] =
    useState({
      weightClub: -1,
      weightSumerScout: -1,
      weightSage: -1,
    });

  const resetGradeWeightPreview = () => {
    setGradeWeightPreviewData(undefined);
  };

  const {
    loading: gradeWeightPreviewLoading,
    error: gradeWeightPreviewError,
    data: gradeWeightPreviewData,
  } = useGetGradeWeightPreviewQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      request: {
        clubGradeWeight: gradeWeightPreviewWeightsDataCache.weightClub,
        sumerGradeWeight: gradeWeightPreviewWeightsDataCache.weightSumerScout,
        sageGradeWeight: gradeWeightPreviewWeightsDataCache.weightSage,
        currentSeason: CURRENT_DRAFT_YEAR,
        seasons: SEASONS,
        clubId: CLUB_ID,
      },
    },
    skip:
      (gradeWeightPreviewWeightsDataCache.weightClub === -1 &&
        gradeWeightPreviewWeightsDataCache.weightSumerScout === -1 &&
        gradeWeightPreviewWeightsDataCache.weightSage === -1) ||
      (gradeWeightPreviewWeightsDataCache.weightClub ===
        gradeWeightDataCache.weightClub &&
        gradeWeightPreviewWeightsDataCache.weightSumerScout ===
          gradeWeightDataCache.weightSumerScout &&
        gradeWeightPreviewWeightsDataCache.weightSage ===
          gradeWeightDataCache.weightSage),
    onCompleted: () => {
      setGradeWeightPreviewData(gradeWeightPreviewData);
    },
  });
  const [gradeWeightPreviewDataCache, setGradeWeightPreviewData] = useState(
    gradeWeightPreviewData
  );

  const [
    updateGradeWeights,
    { loading: updateGradeWeightLoading, error: updateGradeWeightError },
  ] = useUpdateGradeWeightMutation({
    onCompleted: (response) => {
      // Update grade weight data using the mutation response to save a refetch
      if (response?.updateGradeWeight) {
        const weightClub = response.updateGradeWeight.find(
          (w) => w.gradeWeightType == GradeWeightEnum.CLUB
        )?.value;
        const weightSumerScout = response.updateGradeWeight.find(
          (w) => w.gradeWeightType == GradeWeightEnum.SUMER_SCOUT
        )?.value;
        const weightSage = response.updateGradeWeight.find(
          (w) =>
            w.gradeWeightType == GradeWeightEnum.SUMER_ANALYTICS_GRADE_ENSEMBLE
        )?.value;

        setGradeWeightData({
          weightClub: weightClub ?? 0,
          weightSumerScout: weightSumerScout ?? 0,
          weightSage: weightSage ?? 0,
        });
      }

      // Null out the preview data since we've committed to these weights
      resetGradeWeightPreview();

      // RVA and PV are calculated using grades so we need to refetch
      rosterValueRefetch();

      toast.success("Grade weight updates complete!", {
        autoClose: 4000,
        position: "bottom-right",
      });
    },

    refetchQueries: ["GetClubIpAuditAttributes"],
  });

  const useGetGradeWeightIds = () => {
    const { data } = useGetGradeWeightsQuery();
    const gradeWeights = data?.gradeWeights || [];
    const sumerScoutWeightId =
      gradeWeights?.find(
        (w) => w.gradeWeightType == GradeWeightEnum.SUMER_SCOUT
      )?.id ?? "no-id";
    const clubWeightId =
      gradeWeights?.find((w) => w.gradeWeightType == GradeWeightEnum.CLUB)
        ?.id ?? "no-id";
    const sageId =
      gradeWeights?.find(
        (w) =>
          w.gradeWeightType == GradeWeightEnum.SUMER_ANALYTICS_GRADE_ENSEMBLE
      )?.id ?? "no-id";
    return { sumerScoutWeightId, clubWeightId, sageId };
  };
  const { sumerScoutWeightId, clubWeightId, sageId } = useGetGradeWeightIds();

  type GradeWeight = {
    id: string;
    value: number;
    gradeWeightType: GradeWeightEnum;
  };

  const updateGradeWeightsWrapper = (
    weightClub: number,
    weightSumerScout: number,
    weightSage: number
  ) => {
    const updatedWeights: GradeWeight[] = [];
    updatedWeights.push({
      id: sumerScoutWeightId,
      value: weightSumerScout,
      gradeWeightType: GradeWeightEnum.SUMER_SCOUT,
    });
    updatedWeights.push({
      id: clubWeightId,
      value: weightClub,
      gradeWeightType: GradeWeightEnum.CLUB,
    });
    updatedWeights.push({
      id: sageId,
      value: weightSage,
      gradeWeightType: GradeWeightEnum.SUMER_ANALYTICS_GRADE_ENSEMBLE,
    });

    toast.success("Grade weights now updating", {
      autoClose: 4000,
      position: "bottom-right",
    });

    return updateGradeWeights({
      variables: {
        updateGradeWeightRequest: {
          weights: updatedWeights,
        },
      },
    });
  };

  return (
    <ClubIPGradesDataContext.Provider
      value={{
        getRosterValueQuery: {
          data: rosterValueDataCache,
          loading,
          error,
          networkStatus,
        },
        getSearchPlayersQuery: {
          data: searchPlayersDataCache,
          loading: searchPlayersLoading,
          error: searchPlayersError,
          networkStatus: searchPlayersNetworkStatus,
        },
        overridePlayerGrade: {
          mutation: overrideGradeMutation,
          loading: overrideGradeLoading,
          error: overrideGradeError,
        },
        savePgm: {
          mutation: savePmgMutation,
          loading: savePgmLoading,
          error: savePgmError,
        },
        getPositionalImportanceSurveyQuery: {
          data: piData,
          loading: piLoading,
          error: piError,
        },
        updatePositionalImportance: {
          mutation: updateSurvey,
          loading: updateSurveyLoading,
          error: updateSurveyError,
        },
        getGradeWeightsQuery: {
          weightClub: gradeWeightDataCache.weightClub,
          weightSumerScout: gradeWeightDataCache.weightSumerScout,
          weightSage: gradeWeightDataCache.weightSage,
          loading: gradeWeightLoading,
          error: gradeWeightError,
        },
        getGradeWeightPreviewQuery: {
          setPreviewWeights: debounce(setGradeWeightPreviewWeightData, 200),
          data: gradeWeightPreviewDataCache,
          loading: gradeWeightPreviewLoading,
          error: gradeWeightPreviewError,
          reset: resetGradeWeightPreview,
        },
        useUpdateGradeWeights: {
          mutation: updateGradeWeightsWrapper,
          loading: updateGradeWeightLoading,
          error: updateGradeWeightError,
        },
      }}
    >
      {children}
    </ClubIPGradesDataContext.Provider>
  );
};

export const useClubIPGradesData = () => {
  const context = useContext(ClubIPGradesDataContext);
  if (!context) {
    throw new Error("useClubIPData must be used within a DataProvider");
  }
  return context;
};
