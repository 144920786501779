import { SvgIcon, SxProps } from "@mui/material";
import React from "react";

export const QuestionMarkCircleIcon = (props: { sx?: SxProps }) => {
  return (
    <SvgIcon sx={{ ...props.sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <g clipPath="url(#clip0_3690_89324)">
          <path
            d="M4.545 4.5C4.66255 4.16583 4.89458 3.88405 5.19998 3.70457C5.50538 3.52508 5.86445 3.45947 6.21359 3.51936C6.56273 3.57924 6.87941 3.76076 7.10754 4.03176C7.33567 4.30277 7.46053 4.64576 7.46 5C7.46 6 5.96 6.5 5.96 6.5M6 8.5H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3690_89324">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
