"use client";

import { Stack, styled } from "@mui/material";
import Head from "next/head";
import { MarvalNavbar } from "./navigation/MarvalNavbar";
import { MarvalPage, useNavigationState } from "./navigation/navigationState";
import { HomePage } from "../../components/HomePage";
import { PlayerGrades } from "../../components/clubIP/grades/PlayerGrades";
import { PositionalImportance } from "../../components/clubIP/PositionalImportance";
import { Financials } from "../../components/clubIP/Financials";
import { OtherTeamNeeds } from "../../components/clubIP/OtherTeamNeeds";
import { MyRoster } from "../../components/playerInformation/MyRoster";
import { PlayerDatabase } from "../../components/playerInformation/PlayerDatabase";
import { DraftScenarios } from "../../components/draft/myDrafts/DraftScenarios";
import { useBootIntercom } from "../../services/intercom/intercom";
import { ClubIPDataProvider } from "../../components/clubIP/ClubIPGradesDataProvider";

export default function DraftHome() {
  const { currentPage } = useNavigationState();
  useBootIntercom();

  return (
    <>
      <Stack direction="row">
        <MarvalNavbar />
        <DraftHomeStyled>
          <Head>
            <title>Draft Dashboard</title>
            <meta
              name="description"
              content="Page displaying the draft home for a club"
            />
          </Head>
          {/* Home */}
          {currentPage == MarvalPage.HOME && <HomePage />}

          {/* Draft Tools */}
          {currentPage == MarvalPage.DRAFT_SCENARIOS && <DraftScenarios />}

          {/* Player Information */}
          {currentPage == MarvalPage.MY_ROSTER && <MyRoster />}
          {currentPage == MarvalPage.PLAYER_DATABASE && <PlayerDatabase />}

          {/* Club IP */}
          <ClubIPDataProvider>
            {currentPage == MarvalPage.GRADES && <PlayerGrades />}
            {currentPage == MarvalPage.POSITIONAL_IMPORTANCE && (
              <PositionalImportance />
            )}
          </ClubIPDataProvider>
          {currentPage == MarvalPage.FINANCIALS && <Financials />}
          {currentPage == MarvalPage.TEAM_NEEDS && <OtherTeamNeeds />}
        </DraftHomeStyled>
      </Stack>
    </>
  );
}

const DraftHomeStyled = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100vh)",
  backgroundColor: theme.palette.grey[50],
}));
