import { Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import { SignOutButton } from "../../../components/SignOutButton";
import { SignInButton } from "../../../components/SignInButton";
import { InteractionStatus } from "@azure/msal-browser";
import { NavBarSection, NavLink } from "./MarvalNavbar";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useIntercom } from "react-use-intercom";

export const PersonalLinks = () => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <NavBarSection>
      <NavLink text="Settings" path="/settings">
        <SettingsIcon
          sx={{
            transform: "scale(0.8)",
            color: theme.palette.grey[50],
          }}
        />
      </NavLink>
      <Help />
      <Stack
        p={0.5}
        sx={{
          "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.grey[800],
          },
        }}
      >
        <AuthenticatedTemplate>
          <Myself onClick={handleMenu} />
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <SignOutButton />
            </MenuItem>
          </Menu>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div style={{ flex: 6 }} />
          <SignInButton />
        </UnauthenticatedTemplate>
      </Stack>
    </NavBarSection>
  );
};

const Help = () => {
  const theme = useTheme();
  const { show } = useIntercom();

  return (
    <Stack
      direction="row"
      alignItems="center"
      onClick={() => show()}
      mb={0.5}
      p={0.5}
      sx={{
        "&:hover": {
          cursor: "pointer",
          textDecoration: "none",
          backgroundColor: theme.palette.grey[800],
        },
        textDecoration: "none",
      }}
    >
      <HelpOutlineIcon
        sx={{
          transform: "scale(0.8)",
          color: theme.palette.grey[50],
        }}
      />
      <Typography
        textTransform="none"
        fontSize={16}
        fontWeight={400}
        ml={1}
        color={theme.palette.grey[50]}
      >
        Help
      </Typography>
    </Stack>
  );
};

const Myself = (props: {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
  const { instance, inProgress } = useMsal();
  const account = instance.getAllAccounts()[0];
  const theme = useTheme();

  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4689
  // Don't show anything during an interaction state -- user account info won't be ready
  if (inProgress !== InteractionStatus.None) {
    return null;
  }

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        onClick={props.onClick}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <AccountCircleIcon sx={{ color: theme.palette.grey[50] }} />
        <Typography
          textTransform="none"
          fontSize={16}
          fontWeight={400}
          ml={1}
          color={theme.palette.grey[50]}
        >
          {account.name}
        </Typography>
      </Stack>
    </>
  );
};

export default PersonalLinks;
