import { create } from "zustand";

export enum MarvalPage {
  HOME,
  MY_ROSTER,
  PLAYER_DATABASE,
  DRAFT_SCENARIOS,
  GRADES,
  POSITIONAL_IMPORTANCE,
  FINANCIALS,
  TEAM_NEEDS,
}

interface NavigationState {
  selectedPage: MarvalPage;
  setSelectedPage: (page: MarvalPage) => void;

  currentPage: MarvalPage;
  setCurrentPage: (page: MarvalPage) => void;
}

export const useNavigationState = create<NavigationState>()((set) => ({
  selectedPage: MarvalPage.HOME,
  setSelectedPage: (selectedPage: MarvalPage) => set(() => ({ selectedPage })),

  currentPage: MarvalPage.HOME,
  setCurrentPage: (currentPage: MarvalPage) => set(() => ({ currentPage })),
}));

export default useNavigationState;
