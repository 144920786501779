import {
  CircularProgress,
  Link,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { getClubAttributesFromConfig } from "../../../utils/clubs";
import { clientConfig } from "../../../config/config";
import { ClubLogoImage } from "@sumer/shared/components/design/ClubLogoImage";
import CircleIcon from "@mui/icons-material/Circle";
import { ReactNode, useState } from "react";
import { useParentHeightBoundary } from "@sumer/shared/utils/useParentHeightBoundary";
import { PersonalLinks } from "./PersonalLinks";
import { ClubVaultLinks } from "./ClubVaultLinks";
import { DraftToolsLinks } from "./DraftToolsLinks";
import { HomeLink } from "./HomeLink";
import { MarvalPage, useNavigationState } from "./navigationState";
import { useIntercom } from "react-use-intercom";
import {
  VISITED_DRAFT_SCENARIOS,
  VISITED_OTHER_TEAM_NEEDS,
  VISITED_PLAYER_GRADES,
  VISITED_SETTINGS,
} from "../../../components/draft/shared/intercomEvents";
import { useClubIPEditStore } from "../../../components/clubIP/clubIPEditState";
import { ExitConfirmationModal } from "../../../components/clubIP/ExitConfirmationModal";
import { useRouter } from "next/navigation";

export const MarvalNavbar = () => {
  const [scrollRef, scrollHeight] = useParentHeightBoundary();
  const theme = useTheme();

  return (
    <Stack
      width={280}
      minWidth={280}
      bgcolor={theme.palette.grey[700]}
      sx={{
        boxShadow: `inset 0 -50px 50px -50px ${theme.palette.grey[800]}`,
      }}
    >
      <ScrollableContent ref={scrollRef} style={{ height: scrollHeight }}>
        <MyClub />
        <DividerLine />
        <HomeLink />
        {/* <DividerLine />
        <PlayerInformationLinks /> */}
        <DividerLine />
        <DraftToolsLinks />
        <DividerLine />
        <ClubVaultLinks />
        <DividerLine />
        <PersonalLinks />
      </ScrollableContent>
    </Stack>
  );
};

const MyClub = () => {
  const clubAttributes = getClubAttributesFromConfig(clientConfig.clubCode);
  return (
    <NavBarSection>
      <Stack direction="row" alignItems="center" pl={1}>
        <ClubLogoImage clubCode={clientConfig.clubCode} height={40} />
        <Typography
          fontSize={20}
          fontWeight={500}
          ml={1}
          color="white"
          fontFamily={"massilia-variable"}
        >
          {clubAttributes?.clubName}
        </Typography>
      </Stack>
    </NavBarSection>
  );
};

export const NavLink = (props: {
  text: string;
  path?: string;
  page?: MarvalPage;
  children?: ReactNode;
}) => {
  const { trackEvent } = useIntercom();
  const { currentPage, setCurrentPage, selectedPage, setSelectedPage } =
    useNavigationState();
  const selected = currentPage === props.page;

  const { editing, setShowExitConfirmation } = useClubIPEditStore(
    (state) => state
  );

  const router = useRouter();
  const [navigating, setNavigating] = useState(false);

  const handleNavigation = (page?: MarvalPage, path?: string) => {
    if (page !== undefined) {
      setCurrentPage(page);
    } else if (path !== undefined) {
      setNavigating(true);
      router.push(path);
      setNavigating(false);
    }
  };

  const handleClick = () => {
    if (selected) return;

    if (props.page !== undefined) {
      setSelectedPage(props.page);
    }

    if (props.page === MarvalPage.GRADES) {
      trackEvent(VISITED_PLAYER_GRADES);
    }
    if (props.page === MarvalPage.TEAM_NEEDS) {
      trackEvent(VISITED_OTHER_TEAM_NEEDS);
    }
    if (props.page === MarvalPage.DRAFT_SCENARIOS) {
      trackEvent(VISITED_DRAFT_SCENARIOS);
    }
    if (props.path === "/settings") {
      trackEvent(VISITED_SETTINGS);
    }

    if (editing) {
      setShowExitConfirmation(true);
    } else {
      handleNavigation(props.page, props.path);
    }
  };

  const theme = useTheme();
  return (
    <>
      {selected && (
        <ExitConfirmationModal
          setPage={() => handleNavigation(selectedPage, props.path)}
        />
      )}
      <Stack
        direction="row"
        alignItems="center"
        mb={0.5}
        bgcolor={selected ? theme.palette.primary.main : undefined}
        onClick={handleClick}
        borderRadius={1}
        p={0.5}
        sx={{
          "&:hover": {
            cursor: navigating ? "default" : "pointer",
            backgroundColor: selected
              ? theme.palette.primary.main
              : theme.palette.grey[800],
          },
        }}
      >
        {navigating ? (
          <CircularProgress
            size={15}
            sx={{
              color: theme.palette.grey[50],
              marginLeft: navigating ? 0.5 : 0,
              marginRight: navigating ? 0.5 : 0,
            }}
          />
        ) : props.children ? (
          props.children
        ) : (
          <CircleIcon
            sx={{ transform: "scale(0.7)", color: theme.palette.grey[50] }}
          />
        )}
        <Link
          sx={{
            "&:hover": {
              cursor: navigating ? "default" : "pointer",
              textDecoration: "none",
            },
            textDecoration: "none",
          }}
        >
          <Typography
            textTransform="none"
            fontSize={16}
            fontWeight={400}
            ml={1}
            color={theme.palette.common.white}
          >
            {props.text}
          </Typography>
        </Link>
      </Stack>
    </>
  );
};

export default MarvalNavbar;

export const NavBarSection = styled(Stack)(() => ({
  padding: "20px 15px 20px 15px",
}));

const DividerLine = styled("div")(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[500]}`,
  height: "1px",
  display: "inline-block",
}));

const ScrollableContent = styled(Stack)(() => ({
  overflowY: "scroll",
  "::-webkit-scrollbar": { width: 0, height: 0 },
  display: "flex",
}));
