import {
  useTheme,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { WarningAmber } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDiscardDraftMutation } from "../../../graphql/generated/graphql";
import { Modal, ModalSize } from "../../common/popup/Modal";
import { Button, ButtonSize, ButtonType } from "../../common/button/Button";

export const DiscardDraftScenarioModal = (props: { draftId: string }) => {
  const theme = useTheme();
  const colors = theme.palette;

  const [open, setOpen] = useState(false);
  const [discardDraftMutation, { loading }] = useDiscardDraftMutation({
    variables: {
      request: {
        draftId: props.draftId ?? "no-id",
      },
    },
  });

  const Trigger = (
    <>
      <Tooltip title="Discard Draft Scenario">
        <IconButton onClick={() => setOpen(true)} disabled={loading}>
          {loading ? (
            <CircularProgress color="primary" size="0.8em" />
          ) : (
            <DeleteOutlineIcon
              sx={{
                transform: "scale(1.0)",
                color: colors.error.main,
              }}
            />
          )}
        </IconButton>
      </Tooltip>
    </>
  );

  const Header = (
    <Stack direction="row" alignItems="center">
      <WarningAmber />
      <Typography fontSize={18} fontWeight={500} ml={1}>
        Discard Draft Scenario
      </Typography>
    </Stack>
  );

  const Body = (
    <Typography fontSize={14}>
      Are you sure you want to discard this draft scenario? The scenario will be
      removed from the application and you will not be able to recover it.
    </Typography>
  );

  const Footer = (
    <>
      <Button
        onClick={() => setOpen(false)}
        buttonType={ButtonType.Secondary}
        buttonSize={ButtonSize.ExtraSmall}
        sx={{
          marginRight: 1,
          "&:hover": {
            backgroundColor: theme.palette.common.white,
          },
        }}
      >
        <Typography fontSize={14} fontWeight={600}>
          Cancel
        </Typography>
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          discardDraftMutation();
          setOpen(false);
        }}
        autoFocus
        buttonType={ButtonType.Destructive}
        buttonSize={ButtonSize.ExtraSmall}
      >
        <DeleteOutlineIcon
          sx={{
            transform: "scale(0.8)",
            color: theme.palette.error.main,
          }}
        />
        <Typography fontSize={14} fontWeight={600} ml={1}>
          Discard
        </Typography>
      </Button>
    </>
  );

  return (
    <Modal
      trigger={Trigger}
      header={Header}
      body={Body}
      open={open}
      setOpen={setOpen}
      footer={Footer}
      modalSize={ModalSize.Small}
    />
  );
};
