import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { clientConfig } from "../config/config";

export function SignInButton() {
  const { instance } = useMsal();
  return (
    <Button
      color="inherit"
      onClick={() => {
        instance.loginPopup({
          scopes: clientConfig.azureDirectory.customerScopes,
        });
      }}
    >
      Sign In
    </Button>
  );
}
