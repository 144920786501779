import {
  CircularProgress,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { NavBarSection, NavLink } from "./MarvalNavbar";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import ErrorIcon from "@mui/icons-material/Error";
import { MarvalPage } from "./navigationState";
import ListIcon from "@mui/icons-material/List";
import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined";
import { useNavigateLiveDraft } from "../../../components/draft/home/useNavigateLiveDraft";
import { useNavigateScenarios } from "../../../components/draft/home/useNavigateScenarios";
import { useAuthorizePermission } from "../../../components/settings/accessControls/useAuthorizePermission";
import { PermissionEnum } from "../../../utils/perms";

export const DraftToolsLinks = () => {
  const theme = useTheme();

  return (
    <NavBarSection>
      <Typography
        textTransform="none"
        fontSize={20}
        fontWeight={500}
        mb={1}
        color={theme.palette.grey[50]}
        fontFamily={"massilia-variable"}
      >
        Draft Tools
      </Typography>
      <DraftSimulatorLink text="New Draft Scenario" />
      <NavLink text="Draft Scenarios" page={MarvalPage.DRAFT_SCENARIOS}>
        <ListIcon
          sx={{ transform: "scale(0.9)", color: theme.palette.grey[50] }}
        />
      </NavLink>
      {/* <NavLink text="Roster Builder" path="rosterBuilder">
        <ConstructionIcon
          sx={{ transform: "scale(0.9)", color: theme.palette.grey[50] }}
        />
      </NavLink> */}
      <LiveDraftLink text="Live Draft Optimizer" />
    </NavBarSection>
  );
};

export const DraftSimulatorLink = (props: { text: string }) => {
  const { startSimDraft, loading, error } = useNavigateScenarios();

  const { hasPerm } = useAuthorizePermission(
    PermissionEnum.PermissionDraftScenarioReadWrite
  );

  const disabled = !hasPerm || loading;

  const Icon = () => {
    if (error) {
      return (
        <ErrorIcon
          sx={{ transform: "scale(0.8)", color: theme.palette.error.main }}
        />
      );
    }
    if (loading) {
      return (
        <CircularProgress
          size={15}
          sx={{
            color: theme.palette.grey[50],
            marginLeft: loading ? 0.5 : 0,
            marginRight: loading ? 0.5 : 0,
          }}
        />
      );
    }
    return !hasPerm ? (
      <LockIcon
        sx={{ transform: "scale(0.8)", color: theme.palette.grey[400] }}
      />
    ) : (
      <AddIcon
        sx={{ transform: "scale(0.9)", color: theme.palette.grey[50] }}
      />
    );
  };

  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      mb={0.5}
      p={0.5}
      onClick={() => {
        if (!disabled) {
          startSimDraft();
        }
      }}
      sx={{
        "&:hover": {
          cursor: loading ? "default" : "pointer",
          backgroundColor: theme.palette.grey[800],
        },
      }}
    >
      <Icon />
      {!disabled && (
        <Link
          sx={{
            "&:hover": {
              cursor: loading ? "default" : "pointer",
              textDecoration: "none",
            },
            textDecoration: "none",
          }}
        >
          <Typography
            textTransform="none"
            fontSize={16}
            fontWeight={400}
            ml={1}
            color={theme.palette.grey[50]}
          >
            {props.text}
          </Typography>
        </Link>
      )}
      {disabled && (
        <Typography
          textTransform="none"
          fontSize={16}
          fontWeight={400}
          ml={1}
          color={theme.palette.grey[400]}
        >
          {props.text}
        </Typography>
      )}
    </Stack>
  );
};

export const LiveDraftLink = (props: { text: string }) => {
  const theme = useTheme();
  const { handleStart, daysTillDraft, loading, error } = useNavigateLiveDraft();
  const liveDraftLocked = daysTillDraft > 0;

  const Icon = () => {
    if (error) {
      return (
        <ErrorIcon
          sx={{ transform: "scale(0.8)", color: theme.palette.error.main }}
        />
      );
    }
    if (loading) {
      return (
        <CircularProgress
          size={15}
          sx={{
            color: theme.palette.grey[50],
            marginLeft: loading ? 0.5 : 0,
            marginRight: loading ? 0.5 : 0,
          }}
        />
      );
    }
    return liveDraftLocked ? (
      <LockIcon
        sx={{ transform: "scale(0.8)", color: theme.palette.grey[400] }}
      />
    ) : (
      <SensorsOutlinedIcon
        sx={{ transform: "scale(0.9)", color: theme.palette.grey[50] }}
      />
    );
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      mb={0.5}
      p={0.5}
      sx={{
        "&:hover": {
          cursor: loading ? "default" : "pointer",
          backgroundColor: theme.palette.grey[800],
        },
      }}
    >
      <Icon />
      {!liveDraftLocked && (
        <Link
          onClick={handleStart}
          sx={{
            "&:hover": {
              cursor: loading ? "default" : "pointer",
              textDecoration: "none",
            },
            textDecoration: "none",
          }}
        >
          <Typography
            textTransform="none"
            fontSize={16}
            fontWeight={400}
            ml={1}
            color={theme.palette.grey[50]}
          >
            {props.text}
          </Typography>
        </Link>
      )}
      {liveDraftLocked && (
        <Stack direction="row" alignItems="center" mt="2px">
          <Typography
            textTransform="none"
            fontSize={16}
            fontWeight={400}
            ml={1}
            mr={0.5}
            color={theme.palette.grey[400]}
          >
            {props.text}
          </Typography>
          <Typography
            textTransform="none"
            fontSize={12}
            fontWeight={500}
            ml={0.5}
            mt={0.5}
            color={theme.palette.grey[400]}
          >
            ({daysTillDraft} days)
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default DraftToolsLinks;
