import { Box, Stack, Typography, useTheme } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useState } from "react";
import { FiltersDrawer } from "./filters/FiltersDrawer";
import { Button, ButtonSize, ButtonType } from "../../common/button/Button";
import { GridQuickFilterToolbar } from "../../common/grids/GridQuickFilterToolbar";
import { TabStyle, Tabs } from "../../common/tabs/Tabs";
import {
  DraftScenarioStatus,
  useGetClubIpAuditAttributesQuery,
} from "../../../graphql/generated/graphql";
import { AlertTriangleIcon } from "../../common/icons/AlertTriangleIcon";
import moment from "moment";

enum StatusFilter {
  IN_PROGRESS = "In-Progress",
  COMPLETED = "Completed",
}

export const MyDraftsToolbar = (props: {
  enableFilters?: boolean;
  showStatusFilter: boolean;
  statusFilter: DraftScenarioStatus;
  setStatusFilter: (status: DraftScenarioStatus) => void;
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  const [filtersOpen, setFiltersOpen] = useState(false);
  const tabs = [StatusFilter.IN_PROGRESS, StatusFilter.COMPLETED];
  const initialTab =
    props.statusFilter === DraftScenarioStatus.COMPLETE
      ? StatusFilter.COMPLETED
      : StatusFilter.IN_PROGRESS;
  const [currentTab, setCurrentTab] = useState<string>(initialTab);
  const handleSetCurrentTab = (tab: string) => {
    setCurrentTab(tab);
    if (tab === StatusFilter.IN_PROGRESS) {
      props.setStatusFilter(DraftScenarioStatus.ACTIVE);
    } else if (tab === StatusFilter.COMPLETED) {
      props.setStatusFilter(DraftScenarioStatus.COMPLETE);
    }
  };

  // refetchQueries called by getClubIPlayerGradeColumns, PositionalWeights, ClubIPDataProvider
  const { data } = useGetClubIpAuditAttributesQuery();
  const lastChangeTimestamp = moment
    .utc(data?.clubIpAuditAttributes)
    .local()
    .format("MM/DD/YY [at] hh:mma");

  return (
    <>
      <Typography
        fontSize={28}
        fontWeight={700}
        color={colors.grey[800]}
        fontFamily={"massilia-variable"}
        mb={-1}
      >
        {!props.showStatusFilter ? "In-Progress" : ""} Draft Scenarios
      </Typography>
      {props.showStatusFilter && (
        <Box mt={3} mb={1}>
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={handleSetCurrentTab}
            style={TabStyle.Secondary}
          />
        </Box>
      )}
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <Stack direction="row" alignItems="center">
            <GridQuickFilterToolbar searchText="Scenario Search" />
            <Stack direction="row" mt={2}>
              <AlertTriangleIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  margin: "3px 8px 0 18px",
                }}
              />
              <Typography fontSize={14}>Last Club Vault change:</Typography>
              <Typography fontSize={14} fontWeight={600} pl={1}>
                {/* ex: 09/16/23 at 08:31am */}
                {lastChangeTimestamp}
              </Typography>
            </Stack>
          </Stack>
          {props.enableFilters && (
            <>
              <Button
                buttonType={ButtonType.Secondary}
                buttonSize={ButtonSize.ExtraSmall}
                onClick={() => setFiltersOpen(true)}
                sx={{ height: 35, marginTop: "8px" }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    fontSize={15}
                    fontWeight={400}
                    color={colors.grey[600]}
                    mr={0.5}
                    pt={"5px"}
                    fontFamily={"massilia-variable"}
                  >
                    Filters
                  </Typography>
                  <FilterListIcon
                    sx={{ color: colors.grey[600], transform: "scale(0.9)" }}
                  />
                </Stack>
              </Button>
              <FiltersDrawer
                open={filtersOpen}
                onClose={() => setFiltersOpen(false)}
              />
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
