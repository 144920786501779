import { Stack, Typography, styled, useTheme } from "@mui/material";

export const PillLegend = () => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      mt={2}
      flex={1}
      justifyContent="center"
    >
      <Stack direction="row" alignItems="center">
        <Typography
          fontSize={14}
          fontWeight={400}
          color={theme.palette.grey[700]}
          mr={0.5}
        >
          Default Team Needs (Sumer Analytics):
        </Typography>
        <CircleShadow color={theme.palette.common.white} />
      </Stack>
      <Typography color={theme.palette.grey[200]} mx={2}>
        |
      </Typography>
      <Stack direction="row" alignItems="center">
        <Typography
          fontSize={14}
          fontWeight={400}
          color={theme.palette.grey[700]}
          mr={0.5}
        >
          Custom Team Needs:
        </Typography>
        <CircleShadow color={theme.palette.grey[200]} />
      </Stack>
      <Typography color={theme.palette.grey[200]} mx={2}>
        |
      </Typography>
      <Stack direction="row" alignItems="center" mr={3}>
        <Typography
          fontSize={14}
          fontWeight={400}
          color={theme.palette.grey[700]}
          mr={0.5}
        >
          Selected Team:
        </Typography>
        <CircleShadow color={theme.palette.primary.main} />
      </Stack>
    </Stack>
  );
};

const CircleShadow = styled(Stack)<{ color: string }>(({ color }) => ({
  borderRadius: "25px",
  padding: "4px",
  flexDirection: "row",
  height: 18,
  minHeight: 18,
  width: 50,
  minWidth: 50,
  border: "1px solid lightgrey",
  backgroundColor: color,
  display: "flex",
  justifyContent: "center",
}));
