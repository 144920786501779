"use client";

import { useState, useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { useStartDraft } from "./useStartDraft";
import { DraftTypeEnum } from "../../../graphql/generated/graphql";
import { useRouter } from "next/navigation";

export const useNavigateScenarios = () => {
  const { trackEvent } = useIntercom();
  const router = useRouter();
  const [navigating, setNavigating] = useState(false);
  const [startDraftError, setStartDraftError] = useState(false);
  const { startNewDraft, createDraftLoading, createDraftError } =
    useStartDraft();

  const loading = navigating || createDraftLoading;
  const error = startDraftError || !!createDraftError;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (createDraftLoading) {
      timeoutId = setTimeout(() => {
        setStartDraftError(true);
        setNavigating(false);
      }, 10000);
    }
    return () => clearTimeout(timeoutId);
  }, [createDraftLoading]);

  const startSimDraft = () => {
    startNewDraft(DraftTypeEnum.SIMULATION, (data) => {
      if (data.draftScenarioCreate) {
        setNavigating(true);
        const newDraftId = data.draftScenarioCreate?.id;
        trackEvent("CLICKED_START_SIMULATION", { draftId: newDraftId });
        router.push(`/draft/${newDraftId}?sim=true`);
      } else {
        setStartDraftError(true);
        setNavigating(false);
      }
    });
  };

  return { startSimDraft, loading, error };
};
