import { Checkbox, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";

export const TradesMadeFilters = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const filterOptions = ["0", "1", "2", "3", "4"];

  const handleFilterChange = (value: string) => {
    if (selectedFilters.includes(value)) {
      setSelectedFilters(selectedFilters.filter((filter) => filter !== value));
    } else {
      setSelectedFilters([...selectedFilters, value]);
    }
  };

  return (
    <Stack mr={2}>
      <Typography
        fontSize={12}
        fontWeight={600}
        color={colors.grey[700]}
        ml={1}
      >
        TRADES MADE
      </Typography>
      <Stack>
        {filterOptions.map((option) => (
          <Stack key={option} direction="row" alignItems="center">
            <Checkbox
              checked={selectedFilters.includes(option)}
              onChange={() => handleFilterChange(option)}
              size="small"
              style={{ color: colors.grey[300] }}
              disabled={true} //temp until we can implement this
            />
            <Typography fontSize={12} color={colors.grey[300]}>
              {option}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
