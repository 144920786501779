import { useParentHeightBoundary } from "@sumer/shared/utils/useParentHeightBoundary";
import { DraftTypeSelection } from "./draft/home/DraftTypeSelection";
import { MyDrafts } from "./draft/myDrafts/MyDrafts";
import { CircularProgress, Stack, styled } from "@mui/material";
import { useAuthorizePermission } from "./settings/accessControls/useAuthorizePermission";
import { PermissionEnum } from "../utils/perms";
import { UnauthorizedContent } from "./settings/accessControls/UnauthorizedContent";

export const HomePage = () => {
  const [scrollRef, scrollHeight] = useParentHeightBoundary();

  const { hasPerm, loading: permsLoading } = useAuthorizePermission(
    PermissionEnum.PermissionDraftScenarioReadWrite
  );

  // Checking Perms
  if (permsLoading) {
    return (
      <ScrollableContent ref={scrollRef} style={{ height: scrollHeight }}>
        <Stack flex={1} justifyContent="center" alignItems="center">
          <CircularProgress color="primary" />
        </Stack>
      </ScrollableContent>
    );
  }

  // No Draft Scenario Access
  if (!hasPerm && !permsLoading) {
    return (
      <Stack flex={1} justifyContent="center" alignItems="center">
        <UnauthorizedContent />
      </Stack>
    );
  }

  return (
    <ScrollableContent ref={scrollRef} style={{ height: scrollHeight }}>
      <DraftTypeSelection />
      <MyDrafts />
    </ScrollableContent>
  );
};

const ScrollableContent = styled(Stack)(() => ({
  overflowY: "scroll",
  "::-webkit-scrollbar": { width: 0, height: 0 },
  display: "flex",
  paddingLeft: "40px",
  paddingRight: "40px",
}));
