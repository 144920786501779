import {
  DraftTypeEnum,
  useGetActiveDraftsQuery,
} from "../../../graphql/generated/graphql";

export const useGetActiveDrafts = () => {
  const { data } = useGetActiveDraftsQuery();
  const activeDrafts = data?.myDraftScenarios ?? [];

  const activeSimulations = activeDrafts.filter(
    (draft) => draft.draftType === DraftTypeEnum.SIMULATION
  );

  const activeLiveDrafts = activeDrafts.filter(
    (draft) =>
      draft.draftType === DraftTypeEnum.LIVE ||
      draft.draftType === DraftTypeEnum.MOCK
  );

  return { activeSimulations, activeLiveDrafts };
};
