import { Box, Stack, styled, Typography, useTheme } from "@mui/material";
import Image, { StaticImageData } from "next/legacy/image";
import { StartSimulation } from "./StartSimulation";
import { StartLiveDraft } from "./StartLiveDraft";

export const DraftTypeSelection = () => {
  return (
    <>
      <DashboardSelectionContainer>
        <StartSimulation />
        <StartLiveDraft />
      </DashboardSelectionContainer>
    </>
  );
};

export const ClubThemedHeader = (props: {
  icon?: StaticImageData;
  image: StaticImageData;
  text: string;
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Box
      sx={{
        background: `linear-gradient(to top left, ${colors.grey[900]}, ${colors.grey[700]})`,
      }}
      borderRadius={"8px 8px 0px 0px"}
      height={200}
      overflow="hidden"
      display="flex"
      position="relative"
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        flex={1}
        position="relative"
      >
        {/*Icon*/}
        {props.icon && (
          <Box display="flex" ml={5} minHeight={120}>
            <Image
              src={props.icon}
              objectFit="fill"
              alt={"SimIcon"}
              width={120}
              height={120}
              layout="fixed"
            />
          </Box>
        )}

        {/*Verbiage*/}
        <Typography
          display="flex"
          fontSize="3em"
          fontWeight={600}
          color={colors.grey[600]}
          sx={{
            background: `linear-gradient(to top right, rgba(52, 58, 63, 0.4), rgba(18, 22, 25, 0.4))`,
          }}
          pl={1}
          pr={1.5}
          ml={3}
          whiteSpace={"nowrap"}
          zIndex={1}
        >
          {props.text.toUpperCase()}
        </Typography>
      </Stack>

      {/*Football image*/}
      <Box
        display="flex"
        position="absolute"
        right={0}
        zIndex={0}
        sx={{ opacity: 0.6 }}
      >
        <Image
          src={props.image}
          alt={"SimFootball"}
          width={300}
          height={200}
          layout="fixed"
        />
      </Box>
    </Box>
  );
};

const DashboardSelectionContainer = styled("div")(() => ({
  display: "flex",
  alignSelf: "stretch",
  marginTop: 50,
  marginBottom: 20,
}));
