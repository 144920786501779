import React, { useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import * as Sentry from "@sentry/nextjs";

import {
  Avatar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/WarningAmber";

import { GridColDef } from "@mui/x-data-grid-premium";

import {
  GradeWeightEnum,
  OverridePlayerGradeRequest,
  SaveProjectedGamesMissedRequest,
  SearchPlayerFragment,
  SearchPlayerProjectedGamesMissedFragment,
} from "../../../graphql/generated/graphql";

import { shortDollars } from "../../draft/shared/dollarUtils";
import { gradeDollarComparator } from "../../draft/shared/gradeUtils";
import { CURRENT_DRAFT_YEAR } from "@sumer/shared/utils/dates";
import { toast } from "react-toastify";
import { clientConfig } from "../../../config/config";
import { Modal, ModalSize } from "../../common/popup/Modal";
import { Button, ButtonType } from "../../common/button/Button";
import { useClubIPGradesData } from "../ClubIPGradesDataProvider";
import { TooltipForGrids } from "../../common/grids/TooltipForGrids";
import { PlayerOutlineIcon } from "../../common/icons/PlayerOutlineIcon";
import { getClubGradeScalingFactor } from "../../../utils/clubStaticData";

dayjs.extend(utc);

export interface ClubIPlayerGradeRow {
  player: SearchPlayerFragment;
  headshotUrl?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  position?: string | null;
  college?: string | null;
  clubGrade?: string | null;
  clubGradeDollar?: number | null;
  sumerScoutGrade?: string | null;
  sumerScoutGradeDollar?: number | null;
  sageGrade?: string | null;
  sageGradeDollar?: number | null;
  pv?: number | null | undefined;
  pvPreview?: number | null | undefined;
  rva?: number | null;
  rvaPreview?: number | null;
  rvaRank?: number | null;
  pgm?: SearchPlayerProjectedGamesMissedFragment;
}

export const getClubIPlayerGradeColumns =
  (): GridColDef<ClubIPlayerGradeRow>[] => {
    const clubGradeScalingFactor = getClubGradeScalingFactor(
      clientConfig.clubCode
    );

    const columns: GridColDef<ClubIPlayerGradeRow>[] = [
      {
        field: "rank",
        headerName: "Rank",
        width: 60,
        editable: false,
        align: "right",
        headerClassName: "header-styling",
        filterable: false,
        hideable: false,
        disableColumnMenu: true,
        sortingOrder: ["asc", "desc"],
        renderCell: (params) => {
          return (
            <div style={{ fontWeight: "bold" }}>{params.row.rvaRank}.</div>
          );
        },
        valueGetter: (params) => {
          return params.row.rvaRank;
        },
      },
      {
        field: "player",
        headerName: "Player Name",
        minWidth: 250,
        headerClassName: "column-header",
        hideable: false,
        flex: 1,
        editable: false,
        disableColumnMenu: true,
        filterable: false,
        renderCell: (params) => {
          if (!params.row.player.id) return null;
          return (
            <PlayerName
              playerId={params.row.player.id}
              first={params.row.firstName}
              last={params.row.lastName}
              headshot={params.row.headshotUrl}
            />
          );
        },
        valueGetter(params) {
          return `${params.row.firstName} ${params.row.lastName}`;
        },
      },
      {
        field: "pos",
        headerName: "Pos.",
        minWidth: 80,
        headerClassName: "column-header",
        editable: false,
        renderHeader: () => {
          return (
            <TooltipForGrids
              title={"A player's projected primary position."}
              headerName={"Pos."}
              placement="top"
            />
          );
        },
        flex: 1,
        renderCell: (params) => {
          if (!params.row.position) return "--";
          return <Value value={params.row.position} />;
        },
        valueGetter: (params) => params.row.position,
      },
      {
        field: "college",
        headerName: "College",
        minWidth: 150,
        headerClassName: "column-header",
        renderHeader: () => {
          return (
            <TooltipForGrids
              title={"The college that the player most recently attended."}
              headerName={"College"}
              placement="top"
            />
          );
        },
        editable: false,
        flex: 1,
        renderCell: (params) => {
          if (!params.row.college) return "--";
          return <Value value={params.row.college} />;
        },
        valueGetter: (params) => params.row.college,
      },
      {
        field: "rva",
        headerName: "RVA",
        minWidth: 75,
        flex: 1,
        headerClassName: "column-header",
        renderHeader: () => {
          return (
            <TooltipForGrids
              title={
                "The expected on-field performance a player would add to your roster for the next season if selected, expressed in dollars."
              }
              headerName={"RVA"}
              placement="top"
            />
          );
        },
        sortingOrder: ["desc"],
        editable: false,
        renderCell: (params) => {
          return (
            <Value
              value={shortDollars(params.row.rva, undefined, true, "$0")}
              previewValue={
                params.row.rvaPreview != null
                  ? shortDollars(params.row.rvaPreview, undefined, true, "$0")
                  : null
              }
            />
          );
        },
        valueGetter: (params) => params.row.rva,
      },
      {
        field: "pv",
        headerName: "PV",
        minWidth: 75,
        flex: 1,
        headerClassName: "column-header",
        renderHeader: () => {
          return (
            <TooltipForGrids
              title={
                "The expected on-field contribution of an individual player in the context of your roster for the next season, expressed in dollars."
              }
              headerName={"PV"}
              placement="top"
            />
          );
        },
        sortingOrder: ["desc"],
        editable: false,
        renderCell: (params) => {
          return (
            <Value
              value={shortDollars(params.row.pv, undefined, true, "$0")}
              previewValue={
                params.row.pvPreview != null
                  ? shortDollars(params.row.pvPreview, undefined, true, "$0")
                  : null
              }
            />
          );
        },
        valueGetter: (params) => params.row.pv,
      },
      {
        field: "clubGrade",
        headerName: `${clientConfig.clubCode} Grade`,
        minWidth: 110,
        editable: true,
        flex: 1,
        headerClassName: "column-header",
        renderHeader: () => {
          return (
            <TooltipForGrids
              title={
                "Your club's grade for a player in your club's grading scale."
              }
              headerName={`${clientConfig.clubCode} Grade`}
              placement="top"
            />
          );
        },
        renderCell: (params) => {
          if (params.row.clubGrade == null) return "--";
          const scaledClubGrade =
            Number(params.row.clubGrade) / clubGradeScalingFactor;
          return (
            <Grade
              gradeSeason={scaledClubGrade.toFixed(2)}
              gradeSeasonDollar={params.row.clubGradeDollar}
            />
          );
        },
        valueGetter: (params) => {
          const grade = params.row.clubGrade || 0;
          const dollars = params.row.clubGradeDollar || 0;
          return gradeDollarComparator(grade, dollars).valueGetter();
        },
        sortComparator: (v1, v2) => {
          return gradeDollarComparator().sortComparator(v1, v2);
        },
      },
      {
        field: "sumerGrade",
        headerName: "SS Grade",
        minWidth: 110,
        flex: 1,
        headerClassName: "column-header",
        renderHeader: () => {
          return (
            <TooltipForGrids
              title={
                "Sumer's internal scouting department grade on a traditional 1 to 10 scale."
              }
              headerName={"SS Grade"}
              placement="top"
            />
          );
        },
        editable: false,
        renderCell: (params) => {
          if (params.row.sumerScoutGrade == null) return "--";
          return (
            <Grade
              gradeSeason={params.row.sumerScoutGrade}
              gradeSeasonDollar={params.row.sumerScoutGradeDollar}
            />
          );
        },
        valueGetter: (params) => {
          const grade = params.row.sumerScoutGrade || 0;
          const dollars = params.row.sumerScoutGradeDollar || 0;
          return gradeDollarComparator(grade, dollars).valueGetter();
        },
        sortComparator: (v1, v2) => {
          return gradeDollarComparator().sortComparator(v1, v2);
        },
      },
      {
        field: "sageGrade",
        headerName: "SAGE",
        minWidth: 105,
        headerClassName: "column-header",
        renderHeader: () => {
          return (
            <TooltipForGrids
              title={
                "Sumer's advanced analytics grade for a player on a 1 to 10 scale."
              }
              headerName={"SAGE"}
              placement="top"
            />
          );
        },
        editable: false,
        flex: 1,
        renderCell: (params) => {
          if (params.row.sageGrade == null) return "--";
          return (
            <Grade
              gradeSeason={params.row.sageGrade}
              gradeSeasonDollar={params.row.sageGradeDollar}
              showValue={false}
            />
          );
        },
        valueGetter: (params) => params.row.sageGradeDollar,
      },
      {
        field: "pgm",
        headerName: "Projected Games Missed",
        minWidth: 190,
        flex: 1,
        headerClassName: "column-header",
        renderHeader: () => {
          return (
            <TooltipForGrids
              title={
                "The number of games a player is expected to miss in the next season due to injury."
              }
              headerName={"Projected Games Missed"}
              placement="top"
            />
          );
        },
        editable: false,
        renderCell: (params) => {
          if (!params.row.pgm) return "--";
          return <Value value={params.row.pgm?.value.toFixed(1)} />;
        },
        valueGetter: (params) => params.row.pgm?.value,
      },
      {
        field: "editGrade",
        headerName: "Action",
        flex: 1,
        minWidth: 65,
        maxWidth: 65,
        headerClassName: "column-header",
        sortable: false,
        hideable: false,
        align: "center",
        renderCell: (params) => {
          return (
            <EditGradeCell
              playerId={params.row.player.id}
              initialGrade={String(params.row.clubGrade)}
              initialPgm={params.row.pgm?.value}
            />
          );
        },
      },
    ];

    return columns;
  };

export const Value = (props: {
  value?: number | string | null;
  previewValue?: number | string | null | undefined;
  bold?: boolean;
}) => {
  const { previewValue = null } = props;
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <>
      <Typography
        fontSize={14}
        fontWeight={props.bold ? 500 : 400}
        color={colors.grey[600]}
      >
        {props.value || "--"}
      </Typography>
      {previewValue && (
        <Typography
          fontSize={14}
          fontWeight={props.bold ? 500 : 400}
          color="green"
        >
          {"→" + previewValue}
        </Typography>
      )}
    </>
  );
};

const Grade = (props: {
  gradeType?: GradeWeightEnum | null;
  gradeSeason?: string | null;
  gradeSeasonDollar?: number | null;
  clubGradeSeason?: number | null;
  showSalary: boolean;
  showValue: boolean;
  showOutdated: boolean;
}) => {
  if (props.gradeSeason === null) {
    return (
      <Typography fontSize={14} fontWeight={400}>
        -------------------
      </Typography>
    );
  }

  if (props.clubGradeSeason === null) {
    const tooltipText = (
      <div>
        <strong>Inferred Grade</strong>
        <br />
        Please add the {clientConfig.clubCode} grade for this player.
      </div>
    );

    return (
      <Typography fontSize={14} fontWeight={400}>
        <div>
          <Tooltip title={tooltipText}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
                color: "#cfb000",
              }}
            >
              <WarningIcon style={{ transform: "scale(0.8)" }} /> $
              {shortDollars(props.gradeSeasonDollar) == "N/A"
                ? "--"
                : shortDollars(props.gradeSeasonDollar)}
            </span>
          </Tooltip>
        </div>
      </Typography>
    );
  }

  if (props.gradeType == GradeWeightEnum.CLUB) {
    return (
      <Typography fontSize={14} fontWeight={400}>
        {props.gradeSeason || "-"} |{" "}
        {shortDollars(props.gradeSeasonDollar) == "N/A"
          ? "--"
          : shortDollars(props.gradeSeasonDollar)}
      </Typography>
    );
  }

  if (props.showValue) {
    return (
      <Typography fontSize={14} fontWeight={400}>
        {props.gradeSeason || "-"}{" "}
        {props.showSalary
          ? ` | ${
              shortDollars(props.gradeSeasonDollar) == "N/A"
                ? "--"
                : shortDollars(props.gradeSeasonDollar)
            }`
          : ``}
      </Typography>
    );
  }

  return (
    <Typography fontSize={14} fontWeight={400}>
      {shortDollars(props.gradeSeasonDollar) == "N/A"
        ? "-"
        : shortDollars(props.gradeSeasonDollar)}
    </Typography>
  );
};

Grade.defaultProps = {
  showSalary: true,
  showValue: true,
  showOutdated: false,
};

const EditGradeCell = (props: {
  playerId?: string | null;
  initialGrade?: string | null;
  initialPgm?: number | null;
}) => {
  const theme = useTheme();
  const colors = theme.palette;
  const [open, setOpen] = useState(false);
  const [grade, setGrade] = useState(props.initialGrade);
  const [pgm, setPgm] = useState(props.initialPgm?.toString() || "");

  const {
    overridePlayerGrade: {
      mutation: overrideGradeMutation,
      loading: overrideGradeLoading,
    },
    savePgm: { mutation: setSavePgm, loading: setPgmLoading },
  } = useClubIPGradesData();

  const handleClose = () => setOpen(false);
  const handleSave = async () => {
    if (!props.playerId) {
      toast.error("Player ID is missing", {
        autoClose: 4000,
        position: "bottom-right",
      });
      return;
    }

    const pgmNumber = parseFloat(pgm);
    if (pgm != "" && (isNaN(pgmNumber) || pgmNumber < 0)) {
      toast.error("Projected Games Missed must be a positive number", {
        autoClose: 4000,
        position: "bottom-right",
      });
      return;
    }

    const gradeNumber = parseFloat(grade ?? "");
    if (
      grade != "" &&
      grade != undefined &&
      (isNaN(gradeNumber) || gradeNumber < 0)
    ) {
      toast.error("Grade must be a positive number", {
        autoClose: 4000,
        position: "bottom-right",
      });
      return;
    }

    try {
      if (grade != "" && gradeNumber.toString() !== props.initialGrade) {
        const overridePlayerGradeRequest: OverridePlayerGradeRequest = {
          playerId: props.playerId,
          season: CURRENT_DRAFT_YEAR,
          rawValueOverride: gradeNumber.toString(),
        };

        await overrideGradeMutation({
          variables: { overridePlayerGradeRequest },
          refetchQueries: ["GetClubIpAuditAttributes"],
        });
      }

      if (pgm != "" && pgmNumber !== props.initialPgm) {
        const saveProjectedGamesMissedRequest: SaveProjectedGamesMissedRequest =
          {
            playerId: props.playerId,
            season: CURRENT_DRAFT_YEAR,
            value: pgmNumber,
          };

        await setSavePgm({
          variables: { saveProjectedGamesMissedRequest },
          refetchQueries: ["GetClubIpAuditAttributes"],
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Error updating data", {
        autoClose: 4000,
        position: "bottom-right",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <Modal
      onEnterKey={() => {
        const canSave =
          !overrideGradeLoading &&
          !setPgmLoading &&
          !(grade === props.initialGrade && pgm === String(props.initialPgm));

        if (canSave) {
          handleSave();
        } else {
          handleClose();
        }
      }}
      trigger={
        <EditIcon
          data-testid="edit-player-grade-btn"
          onClick={() => setOpen(true)}
          sx={{
            color: colors.grey[500],
            "&:hover": {
              cursor: "pointer",
              color: colors.common.black,
            },
          }}
        />
      }
      header={
        <Typography fontSize={18} fontWeight={500}>
          Edit Grade
        </Typography>
      }
      body={
        <>
          <TextField
            autoFocus
            margin="dense"
            label="Grade"
            fullWidth
            variant="outlined"
            value={grade}
            onChange={(e) => setGrade(e.target.value)}
          />
          <TextField
            margin="dense"
            label="PGM"
            fullWidth
            variant="outlined"
            value={pgm}
            onChange={(e) => setPgm(e.target.value)}
          />
        </>
      }
      open={open}
      setOpen={setOpen}
      footer={
        <Stack flexDirection="row" justifyContent="end">
          <Button
            buttonType={ButtonType.Secondary}
            onClick={handleClose}
            sx={{ marginRight: 2, backgroundColor: colors.grey[50] }}
          >
            Cancel
          </Button>
          <Button
            dark
            onClick={handleSave}
            disabled={
              overrideGradeLoading ||
              setPgmLoading ||
              (grade === props.initialGrade && pgm === String(props.initialPgm))
            }
          >
            Save
          </Button>
        </Stack>
      }
      modalSize={ModalSize.Small}
    />
  );
};

export const PlayerName = (props: {
  playerId?: string | null;
  first?: string | null;
  last?: string | null;
  headshot?: string | null;
}) => {
  const colors = useTheme().palette;
  const playerName = props.first + " " + props.last;
  const playerHeadshot = props.headshot
    ? props.headshot?.replace("{formatInstructions}", `c_fit,h_${100},w_${100}`)
    : null;

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="center">
      {playerHeadshot ? (
        <Avatar
          src={playerHeadshot}
          sx={{
            width: "25px",
            height: "25px",
            border: 1,
            borderColor: colors.grey[300],
          }}
        />
      ) : (
        <PlayerOutlineIcon
          sx={{
            width: "25px",
            height: "25px",
            padding: "4px",
            border: 1,
            color: colors.grey[600],
            borderColor: colors.grey[300],
            backgroundColor: colors.grey[50],
            borderRadius: "50%",
          }}
        />
      )}
      <Stack flexDirection="row" alignItems="center">
        {/* TODO: can add the playerDetails panel here if needed */}
        <Typography fontSize="1em" fontWeight={600} ml={1.2}>
          {playerName}
        </Typography>
      </Stack>
    </Stack>
  );
};
