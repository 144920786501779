import {
  CircularProgress,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { ClubSelection } from "./teamNeeds/ClubSelection";
import { PillLegend } from "./teamNeeds/PillLegend";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useParentHeightBoundary } from "@sumer/shared/utils/useParentHeightBoundary";
import { ClubLogoImage } from "../design/ClubLogoImage";
import { useTeamNeedsState } from "./teamNeeds/teamNeedsState";
import { getClubFromCode, getClubId } from "@sumer/shared/utils/clubStaticData";
import { AvailablePositions } from "./teamNeeds/AvailablePositions";
import { useClubIPEditStore } from "./clubIPEditState";
import { useCallback, useEffect } from "react";
import { SaveDiskIcon } from "../common/icons/SaveDiskIcon";
import { useOverrideTeamNeedsMutation } from "../../graphql/generated/graphql";
import { DraggableTeamNeedsCard } from "./teamNeeds/DraggableTeamNeedsCard";
import {
  EmptyTeamNeedsCard,
  TeamNeedsLoading,
} from "./teamNeeds/TeamNeedsCard";
import { Button, ButtonSize, ButtonType } from "../common/button/Button";
import { useResetTeamNeeds } from "./teamNeeds/useResetTeamNeeds";
import { clientConfig } from "../../config/config";
import { useAuthorizePermission } from "../settings/accessControls/useAuthorizePermission";
import { PermissionEnum } from "../../utils/perms";
import { UnauthorizedContent } from "../settings/accessControls/UnauthorizedContent";

export const OtherTeamNeeds = () => {
  const theme = useTheme();
  const [scrollRef, scrollHeight] = useParentHeightBoundary();

  const { hasPerm: canRead, loading: readCheckLoading } =
    useAuthorizePermission(PermissionEnum.PermissionTeamNeedsRead);

  const { hasPerm: canWrite, loading: writeCheckLoading } =
    useAuthorizePermission(PermissionEnum.PermissionTeamNeedsWrite);

  const permsLoading = readCheckLoading || writeCheckLoading;

  const {
    needs,
    selectedClub,
    setSelectedClub,
    setNeeds,
    changed,
    setChanged,
  } = useTeamNeedsState((state) => state);

  const { setEditing } = useClubIPEditStore((state) => state);
  useEffect(() => {
    setEditing(changed);
  }, [changed, setEditing]);

  const { resetNeedsForClub, resetLoading } = useResetTeamNeeds();

  // Reset club and needs when user navigates to differnt page
  useEffect(() => {
    return () => {
      setSelectedClub(clientConfig.clubCode);
      resetNeedsForClub(clientConfig.clubCode);
    };
  }, [setSelectedClub, resetNeedsForClub]);

  // Reset needs when club changes (and on initial load)
  useEffect(() => {
    resetNeedsForClub(selectedClub);
  }, [resetNeedsForClub, selectedClub]);

  const [overrideTeamNeedsMutation, { loading: mutationLoading }] =
    useOverrideTeamNeedsMutation({
      variables: {
        orverrideTeamNeedsForClubRequest: {
          clubId: getClubId(selectedClub) ?? "no-club",
          sumerGeneralPositions: needs.map((need) => need.shortName),
        },
      },
      onCompleted: () => {
        setChanged(false);
      },
    });

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      setChanged(true);
      const newCards = [...needs];
      const [draggedCard] = newCards.splice(dragIndex, 1);
      newCards.splice(hoverIndex, 0, draggedCard);
      setNeeds(newCards);
    },
    [needs, setNeeds, setChanged]
  );

  // Checking Perms
  if (permsLoading) {
    return (
      <ScrollableContent ref={scrollRef} style={{ height: scrollHeight }}>
        <Stack flex={1} justifyContent="center" alignItems="center">
          <CircularProgress color="primary" />
        </Stack>
      </ScrollableContent>
    );
  }

  // No Access
  if (!canRead && !permsLoading) {
    return (
      <Stack flex={1} justifyContent="center" alignItems="center">
        <UnauthorizedContent />
      </Stack>
    );
  }

  return (
    <ScrollableContent ref={scrollRef} style={{ height: scrollHeight }}>
      <Header>
        <Stack flex={1} mb={3}>
          <Typography
            fontSize={24}
            fontWeight={600}
            color={theme.palette.textColor.dark}
            fontFamily={"massilia-variable"}
          >
            Team Needs
          </Typography>
          <Typography
            fontSize={16}
            color={theme.palette.textColor.dark}
            mt={0.5}
          >
            {`Select a team below to ${canWrite ? "edit" : "view"
              } their positional needs`}
          </Typography>
        </Stack>
      </Header>
      <Stack flex={1} px="40px" mt={2}>
        <Stack mb={4}>
          <PillLegend />
          <ClubSelection />
        </Stack>
        <Section>
          <Stack flex={1}>
            <Stack
              direction="row"
              alignItems="center"
              flex={1}
              justifyContent="space-between"
              mb={1}
            >
              <Stack direction="row" alignItems="center">
                <ClubLogoImage clubCode={selectedClub} height={32} />
                <Typography
                  color={theme.palette.grey[700]}
                  fontSize={20}
                  fontWeight={700}
                  ml={2}
                  fontFamily={"massilia-variable"}
                >
                  {getClubFromCode(selectedClub)?.clubName}
                </Typography>
              </Stack>

              {canWrite && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Button
                    onClick={() => resetNeedsForClub(selectedClub)}
                    disabled={!changed || mutationLoading || resetLoading}
                    buttonType={ButtonType.Secondary}
                    buttonSize={ButtonSize.Medium}
                    data-testid="cancel-team-needs-btn"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => overrideTeamNeedsMutation()}
                    disabled={!changed || mutationLoading || resetLoading}
                    buttonSize={ButtonSize.Medium}
                    data-testid="save-team-needs-btn"
                  >
                    Save
                    <SaveDiskIcon
                      sx={{ width: "20px", height: "20px", marginLeft: "5px" }}
                    />
                  </Button>
                </Stack>
              )}
            </Stack>
            <DividerLine />
            <Stack direction="row" mt={4}>
              {/* Team Needs List */}
              <DndProvider backend={HTML5Backend}>
                <Stack mr={3}>
                  <Typography
                    color={theme.palette.grey[700]}
                    fontSize={16}
                    fontWeight={500}
                    mb={1}
                    ml={"22px"}
                  >
                    Team Needs
                  </Typography>
                  <Stack direction="row">
                    <Stack mr={1.5} mt={1} spacing={"23px"}>
                      {[...Array(8)].map((_, index) => (
                        <Typography
                          key={index}
                          color={theme.palette.grey[600]}
                          fontSize={18}
                          fontWeight={500}
                          mb={1}
                        >
                          {index + 1}
                        </Typography>
                      ))}
                    </Stack>
                    {resetLoading && <TeamNeedsLoading />}
                    {!resetLoading && (
                      <Stack data-testid="team-needs-col">
                        {needs.map((need, index) => {
                          return (
                            <DraggableTeamNeedsCard
                              key={`filled-slot-${index}`}
                              index={index}
                              id={index}
                              position={need}
                              moveCard={moveCard}
                              readOnly={!canWrite}
                            />
                          );
                        })}
                        {needs.length < 8 &&
                          [...Array(8 - needs.length)].map((card, index) => {
                            return (
                              <div key={`empty-slot-${index}`}>
                                <EmptyTeamNeedsCard />
                              </div>
                            );
                          })}
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </DndProvider>

              {/* Position List */}
              <AvailablePositions readOnly={!canWrite} />
            </Stack>
          </Stack>
        </Section>
      </Stack>
    </ScrollableContent>
  );
};

const Section = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "20px",
  flexDirection: "column",
  marginBottom: "20px",
  borderRadius: theme.borderRadius.medium,
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.boxShadow.medium,
  border: theme.border.primary,
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "20px 0px 0px 20px",
  backgroundColor: theme.palette.common.white,
  borderBottom: theme.border.primary,
}));

const ScrollableContent = styled(Stack)(() => ({
  overflowY: "scroll",
  "::-webkit-scrollbar": { width: 0, height: 0 },
  display: "flex",
}));

const DividerLine = styled("div")(({ theme }) => ({
  borderBottom: theme.border.primary,
  flex: 1,
  display: "flex",
}));
