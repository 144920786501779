import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";

export function SignOutButton() {
  const { instance } = useMsal();
  return (
    <Button
      color="inherit"
      onClick={() => {
        instance.logoutPopup({ mainWindowRedirectUri: "/" });
      }}
      data-testid="sign-out-btn"
    >
      Sign Out
    </Button>
  );
}
