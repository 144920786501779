import {
  Box,
  Skeleton,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { AvailablePositionCard } from "./AvailablePositionCard";
import { positionFullName } from "./positionSet";
import { TeamNeed } from "./teamNeedsState";
import {
  SideOfBall,
  useGetTeamNeedsPositionsQuery,
} from "../../../graphql/generated/graphql";

export const AvailablePositions = (props: { readOnly: boolean }) => {
  const readOnly = props.readOnly;
  const { data, loading } = useGetTeamNeedsPositionsQuery();
  const offense: TeamNeed[] =
    data?.positions
      .filter((p) => p.sideOfBall == SideOfBall.OFFENSE)
      .map((p) => ({
        name: positionFullName(p.shortName),
        shortName: p.shortName,
      })) ?? [];
  const defense: TeamNeed[] =
    data?.positions
      .filter((p) => p.sideOfBall == SideOfBall.DEFENSE)
      .map((p) => ({
        name: positionFullName(p.shortName),
        shortName: p.shortName,
      })) ?? [];
  const specialTeams: TeamNeed[] =
    data?.positions
      .filter((p) => p.sideOfBall == SideOfBall.SPECIAL_TEAMS)
      .map((p) => ({
        name: positionFullName(p.shortName),
        shortName: p.shortName,
      })) ?? [];

  return (
    <Stack
      flex={1}
      direction="row"
      ml={3}
      pl={6}
      justifyContent="space-around"
      position="relative"
    >
      <VerticalDivider />
      {loading ? (
        <>
          <PositionGroupLoading name="Offense" />
          <PositionGroupLoading name="Defense" />
          <PositionGroupLoading name="Special Teams" />
        </>
      ) : (
        <>
          <PositionGroup
            name="Offense"
            positions={offense}
            readonly={readOnly}
          />
          <PositionGroup
            name="Defense"
            positions={defense}
            readonly={readOnly}
          />
          <PositionGroup
            name="Special Teams"
            positions={specialTeams}
            readonly={readOnly}
          />
        </>
      )}
    </Stack>
  );
};

const PositionGroup = (props: {
  name: string;
  positions: TeamNeed[];
  readonly: boolean;
}) => {
  const theme = useTheme();

  return (
    <Stack flex={1}>
      <Typography
        color={theme.palette.grey[700]}
        fontSize={16}
        fontWeight={500}
        mb={1}
      >
        {props.name}
      </Typography>
      <Stack>
        {props.positions.map((pos, index) => {
          return (
            <div key={index}>
              <AvailablePositionCard
                position={{
                  name: pos.name,
                  shortName: pos.shortName,
                }}
                readonly={props.readonly}
              />
            </div>
          );
        })}
      </Stack>
    </Stack>
  );
};

const PositionGroupLoading = (props: { name: string }) => {
  const theme = useTheme();
  return (
    <Stack flex={1}>
      <Typography
        color={theme.palette.grey[700]}
        fontSize={16}
        fontWeight={500}
        mb={1}
      >
        {props.name}
      </Typography>
      <Stack>
        <Stack>
          {[...Array(8)].map((_, index) => {
            return (
              <div key={`loading-position-${index}`}>
                <LoadingPositionCard />
              </div>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export const LoadingPositionCard = () => {
  const theme = useTheme();
  const colors = theme.palette;
  return (
    <Skeleton
      variant="rectangular"
      width={220}
      height={42}
      sx={{
        borderRadius: "4px",
        border: `1px solid ${colors.grey[100]}`,
        backgroundColor: theme.palette.grey[50],
        padding: "8px",
        marginBottom: "8px",
      }}
    />
  );
};

const VerticalDivider = styled(Box)(({ theme }) => ({
  width: "1px",
  height: "82%",
  borderLeft: theme.border.primary,
  position: "absolute",
  left: 0,
  top: "32px",
}));
