import { Box, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { TeamNeed, useTeamNeedsState } from "./teamNeedsState";

export const TeamNeedsCard = (props: {
  position: TeamNeed;
  isDragging?: boolean;
  readOnly: boolean;
}) => {
  const { readOnly } = props;
  const { position } = props;
  const theme = useTheme();
  const colors = theme.palette;
  const { isDragging } = props;

  const { needs, setNeeds, setChanged } = useTeamNeedsState((state) => state);

  const onRemove = (listId: string) => {
    setChanged(true);
    const updatedNeeds = needs.filter((need) => need.listId !== listId);
    setNeeds(updatedNeeds);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width={240}
      border={theme.border.primary}
      borderRadius={theme.borderRadius.small}
      boxShadow={theme.boxShadow.medium}
      mb={1}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isDragging ? theme.palette.grey[50] : "white",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flex={1}
        p={1}
        sx={{
          cursor: readOnly ? "default" : "grab",
        }}
      >
        <Stack direction="row" alignItems="center">
          {!readOnly && (
            <DragIndicator
              sx={{
                color: colors.grey[300],
                "&:hover": { color: "black" },
              }}
            />
          )}
          <Typography fontSize={12} color={colors.grey[700]} ml={1}>
            {position.name} ({position.shortName})
          </Typography>
        </Stack>
      </Stack>

      <Box
        alignItems="center"
        justifyContent="space-between"
        display="flex"
        height="100%"
        p={1}
      >
        {!readOnly && (
          <CloseIcon
            onClick={() => onRemove(position.listId ?? "no-id")}
            sx={{
              cursor: "pointer",
              transform: "scale(0.8)",
              color: colors.grey[500],
              "&:hover": { color: "black" },
            }}
          />
        )}
        {readOnly && <Stack height={24} />}
      </Box>
    </Stack>
  );
};

export const EmptyTeamNeedsCard = () => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width={240}
      height={42}
      border={`1px dashed ${colors.grey[300]}`}
      borderRadius={1}
      mb={1}
      p={1}
      sx={{
        backgroundColor: theme.palette.grey[50],
      }}
    ></Stack>
  );
};

export const LoadingTeamNeedsCard = () => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Skeleton
      variant="rectangular"
      width={240}
      height={42}
      sx={{
        borderRadius: "4px",
        border: `1px dashed ${colors.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
        padding: "8px",
        marginBottom: "8px",
      }}
    />
  );
};

export const TeamNeedsLoading = () => {
  return (
    <Stack>
      {[...Array(8)].map((_, index) => {
        return (
          <div key={`loading-slot-${index}`}>
            <LoadingTeamNeedsCard />
          </div>
        );
      })}
    </Stack>
  );
};
