import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { Box } from "@mui/material";
import { MyDraftsToolbar } from "./MyDraftsToolbar";
import { getMyDraftsColumns, MyDraftsRow } from "./MyDraftsColumns";
import {
  DraftScenarioStatus,
  DraftTypeEnum,
  useGetMyUndiscardedDraftsWithFiltersQuery,
} from "../../../graphql/generated/graphql";
import {
  DateFilterOptions,
  getStartDate,
  useFiltersState,
} from "./filters/filtersState";
import { useDataGridStyles } from "../../common/grids/useDataGridStyles";
import { useState } from "react";

const mapFilterOptionsToTimes = {
  [DateFilterOptions.ANY]: getStartDate(DateFilterOptions.ANY).toISOString(),
  [DateFilterOptions.DAY]: getStartDate(DateFilterOptions.DAY).toISOString(),
  [DateFilterOptions.MONTH]: getStartDate(
    DateFilterOptions.MONTH
  ).toISOString(),
  [DateFilterOptions.WEEK]: getStartDate(DateFilterOptions.WEEK).toISOString(),
  [DateFilterOptions.YEAR]: getStartDate(DateFilterOptions.YEAR).toISOString(),
};

export const DraftScenariosGrid = (props: { showStatusFilter: boolean }) => {
  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      sorting: {
        sortModel: [{ field: "lastUpdated", sort: "desc" }],
      },
    },
  });

  // Column definition
  const columns: GridColDef<MyDraftsRow>[] = getMyDraftsColumns();

  const rows: MyDraftsRow[] = [];

  const { dateFilter } = useFiltersState((state) => state);

  const startDate = mapFilterOptionsToTimes[dateFilter];
  const [statusFilter, setStatusFilter] = useState<DraftScenarioStatus>(
    DraftScenarioStatus.ACTIVE
  );

  const draftQuery = useGetMyUndiscardedDraftsWithFiltersQuery({
    variables: {
      after: startDate,
    },
  });

  const { loading, data } = draftQuery;

  data?.myDraftScenarios
    .filter((draft) => draft.draftType == DraftTypeEnum.SIMULATION)
    .filter((draft) => draft.status == statusFilter)
    .forEach((draft) => {
      rows.push({
        draftId: draft.id,
        name: draft.name,
        status: draft.status,
        type: draft.draftType,
        createdOn: draft.createdAt,
        createdBy: draft.createdBy,
        RV: draft.currentRosterValue?.dollars,
        rvDifference: draft.netRosterValueChangeDollars,
        lastUpdated: draft.lastUpdated,
      });
    });

  return (
    <Box style={{ flexGrow: 1 }}>
      <DataGridPremium
        sx={{
          ...useDataGridStyles(),
          "& .MuiDataGrid-columnHeader:first-child, & .MuiDataGrid-cell:first-child":
            {
              pl: 3,
            },
        }}
        getRowId={(row) => row.draftId}
        autoHeight
        columns={columns}
        rows={rows}
        rowHeight={50}
        rowCount={rows.length}
        loading={loading}
        disableDensitySelector
        pagination
        pageSize={10}
        disableColumnSelector
        disableColumnResize
        disableColumnReorder
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        components={{
          Toolbar: () => {
            return (
              <MyDraftsToolbar
                enableFilters
                showStatusFilter={props.showStatusFilter}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
              />
            );
          },
        }}
        apiRef={apiRef}
        initialState={initialState}
      />
    </Box>
  );
};
