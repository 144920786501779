import { FC } from "react";
import {
  CircularProgress,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useParentHeightBoundary } from "@sumer/shared/utils/useParentHeightBoundary";
import { PositionalWeights } from "./positionalWeights/PositionalWeights";
import { useAuthorizePermission } from "../settings/accessControls/useAuthorizePermission";
import { PermissionEnum } from "../../utils/perms";
import { UnauthorizedContent } from "../settings/accessControls/UnauthorizedContent";

export const PositionalImportance: FC = () => {
  const theme = useTheme();
  const [scrollRef, scrollHeight] = useParentHeightBoundary();

  const { hasPerm: canRead, loading: readLoading } = useAuthorizePermission(
    PermissionEnum.PermissionPosImportanceRead
  );

  const { hasPerm: canEdit, loading: editLoading } = useAuthorizePermission(
    PermissionEnum.PermissionPosImportanceWrite
  );

  const permsLoading = readLoading || editLoading;

  // Checking Perms
  if (permsLoading) {
    return (
      <ScrollableContent ref={scrollRef} style={{ height: scrollHeight }}>
        <Stack flex={1} justifyContent="center" alignItems="center">
          <CircularProgress color="primary" />
        </Stack>
      </ScrollableContent>
    );
  }

  // No PI Access
  if (!canRead && !permsLoading) {
    return (
      <Stack flex={1} justifyContent="center" alignItems="center">
        <UnauthorizedContent />
      </Stack>
    );
  }

  return (
    <ScrollableContent ref={scrollRef} style={{ height: scrollHeight }}>
      <Header>
        <Stack flex={1} mb={3}>
          <Typography
            fontSize={24}
            fontWeight={600}
            color={theme.palette.textColor.dark}
            fontFamily={"massilia-variable"}
          >
            Positional Importance
          </Typography>
          <Typography
            fontSize={16}
            color={theme.palette.textColor.dark}
            mt={0.5}
          >
            {canEdit
              ? "Use the Position Weights to increase/decrease the value of a position to your roster."
              : "View the positional weights of your roster."}
          </Typography>
        </Stack>
      </Header>

      <Stack flex={1} p="40px" pt="20px">
        <PositionalWeights canEdit={canEdit} />
      </Stack>
    </ScrollableContent>
  );
};

const ScrollableContent = styled(Stack)(() => ({
  overflowY: "scroll",
  "::-webkit-scrollbar": { width: 0, height: 0 },
  display: "flex",
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "20px 0px 0px 20px",
  backgroundColor: theme.palette.common.white,
  borderBottom: theme.border.primary,
}));
